import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../services/Auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { apis } from "../../services/Api";

function CoursePlayer() {
    const [errorMsg, setErrorMsg] = useState(false)
    let navigate = useNavigate();
    const started_time = Math.floor(Date.now() / 1000);
    let id = useParams();
    let location = useLocation();
    let { state } = location;
    let cid = "";
    let mid = "";
    let crumbs1 = state?.crumbs || [];
    let crumbs = crumbs1?.length > 0 && [...crumbs1.slice(0, -1)];
    if (location.state !== null) {
        cid = location.state.cid
        mid = location.state.mid
    }

    const newState = {
        cid,
        mid,
        crumbs,
    };
    let iframeRef = useRef(null);
    useEffect(() => {
        if (location.state !== null) {
            apis.moduleResetPost(cid, mid, started_time);
            let url = `https://learningmanager.adobe.com/app/player?lo_id=${cid || id?.id
            }&access_token=${getToken().access_token}`;
            if (mid && mid !== "") {
                url = url + `&module_id=${mid}`;
            }
            iframeRef.current.src = url;
            const closePlayer = (event) => {
                console.log("closePlayer", event)
                if (event.data === "status:close") {
                    navigate(location?.state?.goBackUrl, { state: newState, replace: true });
                }
            };
            localStorage.setItem("url", JSON.stringify(location?.state?.goBackUrl));
            window.addEventListener("message", closePlayer);
          

            return () => {
                window.removeEventListener("message", closePlayer);
            };
        } else {
            setErrorMsg(true)
            navigate("/coursedetails/" + id?.id);
            // const getbackurl = JSON.parse(localStorage.getItem("url"))
            // navigate(getbackurl, { state: newState, replace: true });
        }

    }, []);

    return (
        <>
            {!(errorMsg) ? <div className="coursePlayer">
                <iframe
                    ref={iframeRef}
                    scrolling="no"
                    id="pplayer_iframe"
                    name="pfplayer_frame"
                    allowFullScreen
                    title="Player"
                    style={{
                        display: "block",
                        background: "#000",
                        border: "none",
                        height: "100vh",
                        width: "100vw",
                    }}></iframe>
            </div> : <div><h1 className="my-5 m-auto col-lg-12 noData ">No course found.</h1></div>
            }
        </>
    );
}
export default CoursePlayer;