/* eslint-disable */
import React, { useState, useEffect, Fragment, useContext, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import time_icon from "../../../src/assets/images/timeIcon.png";
import share_icon from "../../../src/assets/images/share.svg";
import share_email_icon from "../../../src/assets/images/share-email.svg";
import copy_url_icon from "../../../src/assets/images/copy-url.svg";
import Form from "react-bootstrap/Form";
import "../SolutionEnablement/SolutionEnablement.css"
import "./CourseDetails.css";
import { NotificationManager } from "react-notifications";
import secondsToHms from "../../common/SecondsToHms/secondsToHms";
import { apis } from "../../services/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RatingFeedBack from "../../common/RatingFeedBack";
import SingleInstanceDetails from "./SingleInstanceDetails";
import MultiInstanceDetails from "./MultiInstanceDetails";
import MultiInstence from "./MultiInstence";
import PrerequisiteCourseDetails from "./PrerequisiteCourseDetails";
import PrerequisiteCourse from "./PrerequisiteCourse";
import { appContext } from "../../context/appContext";
import Modal from "react-bootstrap/Modal";
import failed_icon from "../../assets/images/failed_icon.svg";
import Breadcrumbs from "../../common/Breadcrumbs";
import { useSelector } from "react-redux";
import AssignRoleToTeam from "../RoleSkillsJourney/AssignRoleToTeam";
import DynamicHTMLContent from "../../common/dynamichtmlcontent";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Failure_Icon from "../../assets/images/Failure_Icon.png";
import moment from "moment";
import user1 from "../../assets/images/user-1.svg";
import Loader from "../../common/Loader/Loader";
import FeedbackModal from "../../common/l1feedbackmodal/FeedbackModal";
import CertificateInstanceDetails from "./CertificateInstanceDetails"
import Scroller from "../../common/Scroller/Scroller";
import ErrorScreen from "./ErrorScreen";

export const getLerningObjectCourses = async (course_id) => {

    /////////////////////// this is for nested lp
    try {
        if (course_id) {

            let { data, included } = await apis.getLearningObject(course_id);
            // let { data, included } = dummyData;
            if (data?.attributes?.loType === "learningProgram") {
                let learningObjectInstanceIDs = data?.relationships?.instances?.data?.map((LOI) => LOI.id)
                if (learningObjectInstanceIDs.length) {
                    let allInstanceOfLearningObject = included?.filter(item => learningObjectInstanceIDs?.includes(item.id))
                    let getAllInstanceNameAndCourseIds = allInstanceOfLearningObject?.map(eachInstance => {
                        let tempObject = {};
                        const info = eachInstance?.attributes?.localizedMetadata[0];
                        const expectedLocale = info.locale;
                        tempObject.instanceName = info?.name ?? "";
                        tempObject.instanceId = eachInstance?.id;
                        tempObject.hasPassed = null;
                        tempObject.progressPercent = null;
                        tempObject.sections = data?.attributes?.sections;
                        tempObject.stateOfCourseOrLP = null;

                        if (data?.relationships?.enrollment) {
                            const enrolledCourseData = included?.find(
                                (i) => data?.relationships?.enrollment?.data?.id === i.id,
                            )
                            tempObject.hasPassed = enrolledCourseData?.attributes?.hasPassed
                            tempObject.progressPercent = enrolledCourseData?.attributes?.progressPercent
                            tempObject.stateOfCourseOrLP = enrolledCourseData?.attributes?.state
                        }

                        if (eachInstance) {
                            let subLoInstancesCourseIds = eachInstance?.relationships?.subLoInstances?.data?.map(SLI => SLI.id)
                            if (subLoInstancesCourseIds) {

                                let learningObjectResourceIds = included?.filter(item => subLoInstancesCourseIds?.includes(item?.id))
                                if (learningObjectResourceIds) {
                                    let allCourseIds = []
                                    learningObjectResourceIds?.map(item => {
                                        let id = item?.relationships?.learningObject?.data?.id
                                        const courseDetails = included?.find(item => item?.id === id)
                                        allCourseIds.push({
                                            data: item?.relationships?.loResources?.data?.map(CI => CI.id) ?? [],
                                            courseDetails: courseDetails ?? {}
                                        })
                                    })

                                    if (allCourseIds) {
                                        const result = allCourseIds?.map(each => {
                                            const resourceIds = included?.filter(item => each?.data?.includes(item.id))
                                            let resourceId = []
                                            let resourceId1 = []
                                            let resourceMids = []
                                            resourceIds?.forEach((each) => {
                                                each?.relationships?.resources?.data?.forEach(i => resourceId.push(i.id));
                                            });
                                            
                                            resourceId = resourceId.reduce((unique, id) => {
                                                const baseId = id.split('_').slice(0, -1).join('_');
                                                const existingIndex = unique.findIndex(existingId => existingId.startsWith(baseId));
                                            
                                                if (existingIndex === -1) {
                                                    unique.push(id);
                                                } else if (id.endsWith(`_${expectedLocale}`)) {
                                                    unique[existingIndex] = id;
                                                }
                                            
                                                return unique;
                                            }, []);

                                            resourceIds?.map(
                                                (each) => resourceId1.push(each?.attributes?.loResourceType)
                                            );

                                            resourceIds?.map(
                                                (each) => resourceMids.push(each?.id)

                                            );

                                            const resource = included?.filter((r) => resourceId?.includes(r.id));
                                            const basicInfo = each?.courseDetails?.attributes?.localizedMetadata[0];
                                            const normalInfo = each?.courseDetails?.attributes;

                                            let sortedArray = resource.map(obj => ({ ...obj })).sort((a, b) => {
                                                const indexA = resourceId.indexOf(a.id);
                                                const indexB = resourceId.indexOf(b.id);
                                                return indexA - indexB;
                                            });
                                            
                                            // code to add description of module
                                            sortedArray?.forEach(obj => {
                                                const learningObjectResource = included?.find(i => 
                                                    i?.relationships?.resources?.data?.some(resource => resource.id === obj.id)
                                                );
                                                obj.attributes.description = learningObjectResource?.attributes?.localizedMetadata?.[0]?.description || "";
                                            });

                                            const NewArraywithidandResourseType = [];
                                            for (let i = 0; i < Math.max(resourceId.length, resourceId1.length); i++) {
                                                const obj = {};

                                                if (i < resourceId.length) {
                                                    obj["id"] = resourceId[i];
                                                }

                                                if (i < resourceId1.length) {
                                                    obj["loResourceType"] = resourceId1[i];
                                                }
                                                NewArraywithidandResourseType.push(obj);
                                            }

                                            // Create a map using the id as the key for quick access
                                            const idToName1Map = {};
                                            NewArraywithidandResourseType?.forEach(obj => {
                                                idToName1Map[obj.id] = obj?.loResourceType;
                                            });
                                            sortedArray?.forEach(obj => {
                                                const loResourceType = idToName1Map[obj.id];
                                                if (loResourceType) {
                                                    obj.loResourceType = loResourceType;
                                                }
                                            });

                                            const NewArraywithidandMid = [];
                                            for (let i = 0; i < Math.max(resourceId?.length, resourceMids.length); i++) {
                                                const obj = {};

                                                if (i < resourceId.length) {
                                                    obj["id"] = resourceId[i];
                                                }

                                                if (i < resourceMids.length) {
                                                    obj["Mid"] = resourceMids[i];
                                                }
                                                NewArraywithidandMid.push(obj);
                                            }

                                            // Create a map using the id as the key for quick access
                                            const idToName1Map1 = {};
                                            NewArraywithidandMid?.forEach(obj => {
                                                idToName1Map1[obj.id] = obj.Mid;
                                            });
                                            // Update the existingArray with name1 property
                                            sortedArray?.forEach(obj => {
                                                const Mid = idToName1Map1[obj.id];
                                                if (Mid) {
                                                    obj.Mid = Mid;
                                                    obj.hasPassed = null;
                                                    obj.progressPercent = null;
                                                    obj.resourceType = null;
                                                    obj.resourceSubType = null;
                                                    obj.submissionState = null;
                                                    obj.submissionUrl = null;
                                                    obj.submissionEnabled = null;
                                                    obj.checklistEvaluationStatus = null
                                                    obj.mandatory = null
                                                    obj.loResourceCompletionCount = null
                                                    obj.learnerAttemptInfo = null


                                                    const loResourceGrade = included?.find(
                                                        (i) => i.id.startsWith(Mid) && i.type === "learningObjectResourceGrade",
                                                    )

                                                    const learningObjectResourceType = included?.find(

                                                        (i) => i.id.startsWith(Mid) && i.type === "learningObjectResource",

                                                    )
                                                    if (loResourceGrade || learningObjectResourceType) {

                                                        obj.hasPassed = loResourceGrade?.attributes?.hasPassed
                                                        obj.progressPercent = loResourceGrade?.attributes?.progressPercent
                                                        obj.resourceType = learningObjectResourceType?.attributes?.resourceType || ""
                                                        obj.resourceSubType = learningObjectResourceType?.attributes?.resourceSubType || ""
                                                        obj.submissionState = learningObjectResourceType?.attributes?.submissionState
                                                        obj.mandatory = learningObjectResourceType?.attributes?.mandatory
                                                        obj.submissionUrl = learningObjectResourceType?.attributes?.submissionUrl || ""
                                                        obj.submissionEnabled = learningObjectResourceType?.attributes?.submissionEnabled
                                                        obj.checklistEvaluationStatus = learningObjectResourceType?.attributes?.checklistEvaluationStatus || ""
                                                        obj.loResourceCompletionCount = learningObjectResourceType?.attributes?.loResourceCompletionCount ? learningObjectResourceType?.attributes?.loResourceCompletionCount : sortedArray?.filter(item => item.loResourceType === "Content")?.length
                                                        // obj.learningObjectInstanceModuleLength = sortedArray?.length
                                                        obj.learningObjectInstanceModuleLength = sortedArray?.filter(item => item.loResourceType === "Content")?.length
                                                        obj.loResourceType = learningObjectResourceType?.attributes?.loResourceType
                                                        obj.learnerAttemptInfo = learningObjectResourceType?.attributes?.learnerAttemptInfo;

                                                        //         courseDataObj.loResourceCompletionCount = loObj.attributes.loResourceCompletionCount
                                                        // courseDataObj.learningObjectInstanceModuleLength = sortedArray.length
                                                    }

                                                }
                                            });
                                            let hasPassed = null;
                                            let progressPercent = null;
                                            let stateOfCourseOrLP = null;
                                            if (each?.courseDetails?.relationships?.enrollment) {
                                                const enrolledCourseData = included?.find(
                                                    (i) => each?.courseDetails?.relationships?.enrollment?.data?.id === i.id,
                                                )
                                                hasPassed = enrolledCourseData?.attributes?.hasPassed
                                                progressPercent = enrolledCourseData?.attributes?.progressPercent
                                                stateOfCourseOrLP = enrolledCourseData?.attributes?.state
                                            }
                                            const mandatoryModulesCount = sortedArray?.filter(module => module.mandatory === true)?.length;
                                            return {
                                                id: each?.courseDetails?.id ?? "",
                                                name: basicInfo?.name ?? "",
                                                description: basicInfo.description ?? "",
                                                loType: normalInfo?.loType ?? "",
                                                duration: normalInfo?.duration ?? "",
                                                authorNames: normalInfo?.authorNames ?? "",
                                                imageUrl: normalInfo?.imageUrl ?? "",
                                                course: sortedArray,
                                                isPrerequisiteEnforced: normalInfo?.isPrerequisiteEnforced,
                                                hasPassed: hasPassed,
                                                progressPercent: progressPercent,
                                                isSubLoOrderEnforced: normalInfo?.isSubLoOrderEnforced,
                                                mandatory: true,
                                                unenrollmentAllowed: normalInfo?.unenrollmentAllowed,
                                                // loResourceCompletionCount: mandatoryModulesCount ? mandatoryModulesCount : normalInfo?.loResourceCompletionCount,
                                                loResourceCompletionCount: normalInfo?.loResourceCompletionCount,
                                                stateOfCourseOrLP: stateOfCourseOrLP,
                                                // learningObjectInstanceModuleLength : sortedArray.length,
                                                learningObjectInstanceModuleLength: sortedArray?.filter(item => item.loResourceType === "Content")?.length,
                                                mandatoryModulesCount: mandatoryModulesCount
                                            }
                                        })
                                        tempObject.instance = result
                                    }
                                }

                            }

                        }
                        const updatedResult = data?.relationships?.subLOs?.data?.map(e => {
                            return tempObject?.instance?.find(item => item.id === e.id
                            )
                        })
                        tempObject.instance = updatedResult
                        return tempObject;
                    })
                    return getAllInstanceNameAndCourseIds;

                }

            }
        }
    } catch (error) {
        console.warn(error);
    }

    //sharebtn-dropdown start

    //sharebtn-dropdown end

};

export const getAllJobAids = async (course_id) => {
    try {
        if (course_id) {
            let { data, included } = await apis.getLerningObjectSubResources(course_id);
            if(included) {
                return included
                .filter(item => item.id.startsWith("jobAid") && item.type === "resource")
                .map(item => ({
                    location: item.attributes.location,
                    name: item.attributes.name
                }));
            }

            return null;
        }
    } catch (error) {
        console.warn(error);
    }
}

export const getAllResources = (included) => {
    if(included) {
        return included
        .filter(item => item.id.endsWith("attachment") && item.type === "resource")
        .map(item => ({
            location: item.attributes.location,
            name: item.attributes.name
        }));
    }

    return null;
}

const AllCourses = () => {
    const { course_id, instance_id, prerequisite_id } = useParams();
    const [courseDetails, setCourseDetails] = useState();
    const [courseDetailsIncluded, setCourseDetailsIncluded] = useState([]);
    const [, setCoreContentCompleted] = useState(0);
    const [courseCompleteDate, setCourseCompleteDate] = useState("");
    const [, setCoreContent] = useState([]);
    const [courseInstances, setCourseInstances] = useState([]);
    const [courseInstancesWithTestOut, setCourseInstancesWithTestOut] = useState([]);
    const [containsL1FeedbackInfo, setContainsL1FeedbackInfo] = useState(false)
    const [skillDetails, setSkillDetails] = useState([]);
    const [ratting, setRatting] = useState(0);
    const [learningObjectInstanceEnrollmentDetails, setLearningObjectInstanceEnrollment] = useState();
    const [, setLearningObject] = useState([]);
    const [prerequisiteCourse, setPrerequisiteCourse] = useState([]);
    const [matchingSkills, setMatchingSkills] = useState([]);
    const { skillsListData, roleWiseSkillsData, dispatch } = useContext(appContext);
    const [displayMessageBox, setDisplayMessageBox] = useState(false);
    const [selectedSkillIds, setSelectedSkillIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isManager, setIsManger] = useState(false);
    const { userProfile } = useSelector((state) => state.userProfile);
    const [showModalforUrl, setShowModalforUrl] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [timeOut, setTimeout] = useState([]);
    const [key, setKey] = useState("Module")
    const [rate, setRate] = useState(0);
    const [warningPopup, setWarningPopup] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const [displayMessageBoxForConfirmation, setDisplayMessageBoxForConfirmation] = useState(false);
    const [displayMessageBoxForConfirmationCourse, setDisplayMessageBoxForConfirmationCourse] = useState(false);
    const [selectedOptionForLerner, setSelectedOptionForLerner] = useState("");
    const [enrollmentCounts, setEnrollmentCounts] = useState();
    const [enrollmentInstanceCounts, setEnrollmenInstanceCounts] = useState();
    const [showLoader, setShowLoader] = useState(true)
    const [isLPInstanceList, setIsLPInstanceList] = useState(false)
    const [checkUserRes, setCheckUserRes] = useState();
    const [errorMsg, setErrorMsg] = useState(false)
    const [
        displayMessageBoxForalreadyAssignRoleSkill,
        setDisplayMessageBoxForalreadyAssignRoleSkill
    ] = useState(false);
    const [isDivVisible, setIsDivVisible] = useState(false);
    const [assignToLearner, setAssignToLearner] = useState(false);
    const [disabaledEnrollBtn, setDisabaledEnrollBtn] = useState(false);
    const [jobAids, setJobAids] = useState([]);
    const [fromNavigation, setFromNavigation] = useState(false);
    const [sectionsArr, setSectionsArr] = useState([]);
    const [coreContentItemModule, setcoreContentItemModule] = useState();
    const [mandatoryCountForPrerequisite, setMandatoryCountForPrerequisite] = useState();
    const [countOfPrerequisiteLength, setCountOfPrerequisiteLength] = useState();
    const [isPrerequisiteEnforcedCourse, setIsPrerequisiteEnforcedCourse] = useState();
    const [prerequisiteLengthForCourse, setPrerequisiteLengthForCourse] = useState();
    const [, setCompletionCount] = useState();
    const [resourceAids, setResourceAids] = useState([]);
    const [errorScreen, setErrorScreen] = useState(false);
    const toggleSharebtnDropdown = (input) => {
        const currentUrl = window.location.href;

        if (input === "url") {
            navigator.clipboard
                .writeText(currentUrl)
                .then(() => {
                    setMessageforPopup(
                        "The URL has been successfully copied to your clipboard. You can now easily share it with others by pasting it into emails, messages, or any other platform of your choice."
                    );
                    setShowModalforUrl(true);
                })
                .catch(() => {
                    setMessageforPopup("Something went wrong!");
                    setShowModalforUrl(true);
                });
        }
        if (input === "email") {
            const email = "";
            let subject = "";
            let body = "";

            if (currentUrl.includes("/coursedetails/course")) {
                subject = `Course - ${courseDetails?.attributes?.localizedMetadata[0]?.name}`
                body = `Course Path link - ${currentUrl}`;
            } else if (currentUrl.includes("/coursedetails/learningProgram")) {
                subject = `Learning - ${courseDetails?.attributes?.localizedMetadata[0]?.name}`
                body = `Learning Path link - ${currentUrl}`;
            } else if (currentUrl.includes("/coursedetails/certification")) {
                subject = `Certification - ${courseDetails?.attributes?.localizedMetadata[0]?.name}`
                body = `Certification Path link - ${currentUrl}`;
            }
            else {
                subject = `Course - ${courseDetails?.attributes?.localizedMetadata[0]?.name}`
                body = `Course Path link - ${currentUrl}`;
            }

            const encodedSubject = encodeURIComponent(subject);
            const encodedBody = encodeURIComponent(body);
            window.location.href = `mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`;
        }

        setIsDivVisible(!isDivVisible);
    };
    const setList = () => {
        setIsLPInstanceList(true);
    }
    useEffect(() => {
        // Check if the URL contains a "fromnavigation" query parameter
        const searchParams = new URLSearchParams(location.search);
        const isFromNavigation = searchParams.get("fromNotifications") === "true";
        if (isFromNavigation)
            setFromNavigation(true);
        // Set the state based on the presence of the parameter

    }, []);

    const divRef = useRef(null);
    const handleItemKeyPress = (event) => { if (event.key === "Enter" || event.key === "Space") { toggleSharebtnDropdown(); } };
    const [selectedInstance] = useState(0)
    const [_userId, set_UserId] = useState()
    const [skillPayload, setSkillPayload] = useState()
    const location = useLocation();
    let { state } = location;
    let crumbs1 = state?.crumbs || [];

    if (!crumbs1?.length > 0) {
        crumbs1 = [{ label: "Home", url: "/" },
        { label: "All Courses", url: "/allcourses" }]
    }

    let crumbs =
        [...crumbs1,
        { label: courseDetails?.attributes?.localizedMetadata[0]?.name && `${courseDetails?.attributes?.localizedMetadata[0]?.name}`, url: location },];

    const handleShow = () => setShowModal(!showModal);

    const handleLoader = (val, modal = false, response) => {
        setShowLoader(val)
        if (modal) {
            if (response.status === 200) {
                setMessageForFailure("");
                setMessageforPopup("File uploaded and successfully submitted for approval.");
                setShowModalforUrl(true)
            } else {
                setMessageforPopup("");
                setMessageForFailure("File upload failed.")
                setShowModalforUrl(true)
            }
            apiCall();
        }
    }

    const handleCloseModal = () => {
        setShowModalforUrl(!showModalforUrl);
        setMessageForFailure("")
    };

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showModall, setShowModall] = useState(false);
    const [unenroolId, setunenroolId] = useState()


    // ***********************************
    // to get data for modules 
    function getModulesData(instanceDataObject, includedData) {
        let returnDataObj = {
            loResourceDataObj: {},
            moduleData: [],
            classroomCount: 0,
            seatLimit: "-1",
        }

        let tempCoreContent = []

        let classroomCount = 0
        ////////// Todo - set all data required for course ----------------
        instanceDataObject?.relationships?.loResources?.data?.forEach(

            (learningObjectResourceData) => {
                let tempLoResourceObject = {
                    contentType: "",
                    instructorName: "",
                    location: "",
                    dateStart: "",
                    completionDeadline: "",
                    seatLimit: "-1",
                    duration: "",
                    roomLocation: ""
                }
                let tempObject = {
                    Mid: "",
                    loResourceType: "",
                    attributes: {
                        name: "",
                        contentType: "",
                        duration: 0,
                    },
                    classroomCount: 0,
                    classroomData: {},
                    progressPercent: null,
                    hasPassed: null,
                    id: ""
                }
                let learningObjectResource = includedData?.find((e) => {
                    return e?.id === learningObjectResourceData?.id
                })
                let localizedMetadata =
                    learningObjectResource?.attributes?.localizedMetadata?.find(
                        (e) => {
                            return e?.locale === "en-US"
                        },
                    )

                let loResourceData = includedData?.find((e) => {
                    return (
                        e?.id ===
                        learningObjectResource?.relationships?.resources?.data[0]?.id
                    )
                })
                if ((loResourceData?.attributes?.contentType == "Classroom") || (loResourceData?.attributes?.contentType == "Virtual Classroom")) {
                    let instructorName = ""
                    loResourceData?.attributes?.instructorNames?.forEach(
                        (instructorNameVal) => {
                            if (loResourceData?.attributes?.instructorNames?.length > 1) {
                                instructorName = instructorNameVal + ","
                            } else {
                                instructorName = instructorNameVal
                            }
                        },
                    )
                    tempLoResourceObject.contentType =
                        loResourceData?.attributes?.contentType
                    tempLoResourceObject.completionDeadline =
                        loResourceData?.attributes?.completionDeadline
                    tempLoResourceObject.dateStart = loResourceData?.attributes?.dateStart
                    tempLoResourceObject.instructorName = instructorName
                    tempLoResourceObject.location = loResourceData?.attributes?.location
                    tempLoResourceObject.roomLocation = loResourceData?.attributes?.roomLocation ? loResourceData?.attributes?.roomLocation : null
                    tempLoResourceObject.duration = loResourceData?.attributes?.duration ? loResourceData?.attributes?.duration : null

                    if (loResourceData?.attributes?.seatLimit) {
                        tempLoResourceObject.seatLimit =
                            "" + loResourceData?.attributes?.seatLimit
                    }

                    classroomCount++
                    tempObject.classroomData = tempLoResourceObject
                    // learningObjectResource.relationships.resources.data[0].id
                    returnDataObj.loResourceDataObj = tempLoResourceObject

                }

                tempObject.Mid = learningObjectResourceData?.id
                tempObject.id = learningObjectResource?.relationships?.resources?.data[0]?.id
                tempObject.attributes.duration = loResourceData?.attributes?.desiredDuration
                tempObject.attributes.name = localizedMetadata?.name
                tempObject.attributes.description = localizedMetadata?.description
                tempObject.attributes.contentType = loResourceData?.attributes?.contentType

                const loObj = includedData?.find((item) => {
                    return (
                        item?.id === instanceDataObject?.id?.split("_")[0] &&
                        item?.type === "learningObject"
                    )

                })
                let loObjResourceGradeId = learningObjectResourceData?.id
                const loEnrollmentObjId = loObj?.relationships?.enrollment?.data?.id

                if (loEnrollmentObjId) {
                    loObjResourceGradeId = loObjResourceGradeId.replace(instanceDataObject?.id, loEnrollmentObjId?.substring(0, loEnrollmentObjId?.lastIndexOf("_")))
                }
                let loEnrolledResourceData = includedData?.find((e) => {
                    return (
                        e?.type === "learningObjectResourceGrade" && e?.id.includes(loObjResourceGradeId)
                    )
                })

                tempObject.progressPercent = loEnrolledResourceData?.attributes?.progressPercent
                tempObject.hasPassed = loEnrolledResourceData?.attributes?.hasPassed
                tempObject.mid = learningObjectResource?.relationships?.resources?.data[0]?.id
                tempObject.loResourceType = learningObjectResource?.attributes?.loResourceType
                tempObject.checkList = learningObjectResource?.attributes?.checklistEvaluationStatus || "";
                tempObject.resourceType = learningObjectResource?.attributes?.resourceType || "";
                tempObject.resourceSubType = learningObjectResource?.attributes?.resourceSubType || "";
                tempObject.submissionState = learningObjectResource?.attributes?.submissionState;
                tempObject.mandatory = learningObjectResource?.attributes?.mandatory;
                tempObject.submissionUrl = learningObjectResource?.attributes?.submissionUrl || "";
                tempObject.submissionEnabled = learningObjectResource?.attributes?.submissionEnabled;
                tempObject.learnerAttemptInfo = learningObjectResource?.attributes?.learnerAttemptInfo;
                tempObject.multipleAttempt = learningObjectResource.attributes.multipleAttempt;
                // }
                tempCoreContent.push(tempObject)
            },
        )
        // returnDataObj.seatLimit = instanceDataObject?.attributes?.seatLimit
        returnDataObj.moduleData = tempCoreContent
        returnDataObj.classroomCount = classroomCount
        if (classroomCount == 1) {
            returnDataObj.seatLimit = tempCoreContent[0]?.classroomData?.seatLimit
        } else if (classroomCount > 1) {
            returnDataObj.loResourceDataObj = {}
            //Get lowest seat limit available between the array of classroom modules- specifically for Learning Path
            returnDataObj.seatLimit =
                "" +
                tempCoreContent.reduce(
                    (min, p) =>
                        +p?.classroomData?.seatLimit < min
                            ? +p?.classroomData?.seatLimit
                            : min,
                    +tempCoreContent[0]?.classroomData?.seatLimit,
                )
        }
        return returnDataObj
    }
    // **********************************

    const apiCall = async () => {
        try {
            if (course_id) {
                sessionStorage.removeItem("moduleStatusArr");
                sessionStorage.removeItem("allLoResourceArr");
                sessionStorage.removeItem("certificate-previous-module-status");
                sessionStorage.removeItem("certificate-previous-course-status");
                const loResourceArr = [];
                const allLoResourceArr = [];
                // setShowLoader(true);
                let { data, included } = await apis.getLearningObject(course_id);
                // For course  prequisite required - 
                const prequisiteForCourse = data?.attributes?.isPrerequisiteEnforced || false;
                setIsPrerequisiteEnforcedCourse(prequisiteForCourse)
                /////////////////////////////////////// course prerequisite 
                if (data.attributes.loType === "course") {
                    const preLengthForCourse = data?.relationships?.prerequisiteLOs?.data?.length;
                    setPrerequisiteLengthForCourse(preLengthForCourse)
                }
                /////////////////////////////////////// lp
                // For lp prequisite required 
                const prequisiteConstraints = data?.attributes?.prequisiteConstraints;
                const mandatoryPrerequisiteConstraints = prequisiteConstraints?.filter(constraint => constraint?.mandatory === true);
                const mandatoryCount = mandatoryPrerequisiteConstraints?.length || 0;
                setMandatoryCountForPrerequisite(mandatoryCount);
                const prerequisiteLength = data?.attributes?.prequisiteConstraints?.length;
                setCountOfPrerequisiteLength(prerequisiteLength);
                const loInstanceEnrollment = included?.find(
                    (e) => e.id === data?.relationships?.enrollment?.data?.id && e.type === "learningObjectInstanceEnrollment"
                );
                if (loInstanceEnrollment) {
                    const currentPath = window.location.pathname;
                    const loInstanceId = loInstanceEnrollment?.id?.substring(0, loInstanceEnrollment?.id?.lastIndexOf("_"));
                    // For getting modules sorted  from sections or from subLoInstances
                    const courseArr = [];
                    if (data?.attributes?.sections) {
                        data?.attributes?.sections?.map(section => {
                            section?.loIds?.map(item => {
                                if (item?.startsWith("learningProgram")) {
                                    const loId = item;
                                    const loObj = included?.find(obj => obj.id === loId && obj.type === "learningObject");
                                    loObj?.attributes?.sections?.map(ele => {
                                        ele?.loIds?.map(loId => {
                                            courseArr.push(loId);
                                        });
                                    });
                                } else {
                                    courseArr.push(item);
                                }
                            });
                        })

                    } else {
                        const loInstanceObj = included?.find(item => item.id === loInstanceId && item.type === "learningObjectInstance");
                        loInstanceObj?.relationships?.subLoInstances?.data.map(item => {
                            if (item?.id?.startsWith("learningProgram")) {
                                const loId = item?.id?.substring(0, item?.id?.lastIndexOf("_"));
                                const loObj = included?.find(item => item.id === loId && item.type === "learningObject");
                                loObj?.relationships?.subLOs?.data?.map(ele => {
                                    courseArr.push(ele.id);
                                });
                            } else {
                                courseArr.push(item?.id?.substring(0, item?.id?.lastIndexOf("_")));
                            }
                        });
                    }
                    courseArr.map(id => {
                        const courseLoObjInstance = included?.find(item => item?.id?.startsWith(id) && item?.type === "learningObjectInstance");
                        courseLoObjInstance?.relationships?.loResources?.data?.map(ele => {
                            allLoResourceArr.push(ele.id);
                            const loResourceObj = included?.find(e => e.id === ele.id && e.type === "learningObjectResource");
                            if (loResourceObj?.attributes?.loResourceType === "Content") {
                                let descp = loResourceObj?.attributes?.localizedMetadata[0].description;
                                loResourceArr.push({ id: ele.id, hasPassed: false, progressPercent: 0, description: descp});
                            }
                        });
                    });
                    sessionStorage.setItem("moduleStatusArr", JSON.stringify(loResourceArr));
                    sessionStorage.setItem("allLoResourceArr", JSON.stringify(allLoResourceArr));
                    if (!currentPath?.toLowerCase().endsWith(data?.id?.toLowerCase() + "/" + loInstanceId?.toLowerCase())) {
                        if (currentPath?.toLowerCase().endsWith(data?.id?.toLowerCase())) {
                            navigate(currentPath + "/" + loInstanceId, { replace: true, state: { crumbs: crumbs.slice(0, -1) } });
                        } else {
                            window.location.href = window.location.origin + "/coursedetails/" + data?.id + "/" + loInstanceId
                        }
                    }

                }
                else {
                    const currentURL = window.location.href;
                    if (!(currentURL?.toLowerCase().endsWith(data?.id?.toLowerCase()) || currentURL?.toLowerCase().endsWith(data?.id?.toLowerCase() + "/"))) {
                        const paths = currentURL.split("/");
                        const loInstanceId = paths[5];
                        const courseId = data?.id;
                        const loInstanceIdArray = data?.relationships?.instances?.data?.map(instanceId =>
                            instanceId.id);
                        if (!loInstanceIdArray.includes(loInstanceId) ) {

                           const  includedObj = included.find(e => e.id === data?.relationships?.instances?.data[0]?.id && e.type === "learningObjectInstance");
                               if(includedObj.attributes.isDefault && includedObj.attributes.state === 'Active') {
                                navigate("/coursedetails/" + courseId + "/" + includedObj.id, { replace: true, state: { crumbs: crumbs.slice(0, -1) } });
                               } else {
                                window.location.href = window.location.origin + "/coursedetails/" + courseId + "/";
                               }
                        }
                      
                    }
                }
                setCourseDetails(data);
                // setShowLoader(false);
                setCourseDetailsIncluded(included);

                /////////////////////////////////////// learning program 
                if (data?.attributes?.loType === "learningProgram") {
                    // get all job aids
                    let jobAidsArray = await getAllJobAids(course_id);
                    if(jobAidsArray != null) setJobAids(jobAidsArray);

                    let instancesArr = [];

                    // ------------- section wise data---------
                    let sections = data?.attributes?.sections?.map((perSection) => {
                        return perSection
                    })
                    setSectionsArr(sections)

                    // ------------------------ End section wise data ----------
                    ///// for job aids learning path
                    let lpLoDataInOrderArrayOne = [];
                    if (data?.relationships?.supplementaryLOs?.data?.length > 0) {
                        data?.relationships?.supplementaryLOs?.data?.forEach((loData) => {
                            lpLoDataInOrderArrayOne.push(loData.id)
                        })
                    }
                    let jobids = included?.filter(item => lpLoDataInOrderArrayOne?.includes(item.id))
                    let jobidArray = [];
                    jobids?.forEach((loData) => {
                        jobidArray.push(loData?.attributes?.localizedMetadata[0]?.name)
                    })
                    // TODO - for Resources learning path nested 
                    if (data?.relationships?.subLOs?.data?.length > 0) {
                        let subLosIdDataArray = [];

                        data.relationships.subLOs.data.forEach((item) => {
                            subLosIdDataArray.push(item.id);
                        });
                        let subLosIdsData = included?.filter(item => subLosIdDataArray?.includes(item.id));
                        let idDataArray = [];
                        const allSupplementaryResourceIds = subLosIdsData?.map(item => {

                            item?.relationships?.supplementaryResources?.data?.map(resource => {

                                idDataArray?.push(resource?.id);
                                return (idDataArray)
                            })
                            return idDataArray
                        }

                        );
                        let resourcesDataAid = included?.filter(item => idDataArray?.includes(item.id));
                        let resourcesDataArray = [];
                        resourcesDataAid?.forEach((item) => {
                            if (item.attributes?.name && item.attributes?.location) {
                                resourcesDataArray.push({
                                    name: item.attributes.name,
                                    location: item.attributes.location
                                });
                            }
                        });

                        const allResources = getAllResources(included);
                        setResourceAids(allResources);
                    }
                    else {
                        // for learning path single
                        let resourceDataArrayLP = [];
                        if (data?.relationships?.supplementaryResources?.data?.length > 0) {
                            data.relationships.supplementaryResources.data.forEach((item) => {
                                resourceDataArrayLP.push(item.id);
                            });
                        }

                        let resourcesDataAid = included?.filter(item => resourceDataArrayLP?.includes(item.id));
                        let resourcesDataArray = [];
                        resourcesDataAid?.forEach((item) => {
                            if (item.attributes?.name && item.attributes?.location) {
                                resourcesDataArray.push({
                                    name: item.attributes.name,
                                    location: item.attributes.location
                                });
                            }
                        });
                        const allResources = getAllResources(included);
                        setResourceAids(allResources);
                        // end resource data
                    }

                    const loType = data?.attributes?.loType
                    if (
                        loType == "learningProgram" &&
                        data?.relationships?.instances?.data.length > 1
                    ) {
                        if (!instance_id)
                            setIsLPInstanceList(false)
                    } else {
                        setIsLPInstanceList(true)
                    }
                    let lpLoDataInOrderArray = []
                    if (loType == "learningProgram") {
                        data?.attributes?.sections?.forEach((loData) => {

                            loData?.loIds?.forEach((loitem) => {
                                let loDataItem = {
                                    id: "",
                                    mandatory: false,
                                }

                                loDataItem.id = loitem
                                loDataItem.mandatory = loData?.mandatory
                                lpLoDataInOrderArray.push(loDataItem)
                            })
                        })
                    }

                    if (data?.relationships?.instances?.data?.length > 0) {
                        setDisabaledEnrollBtn(true)
                        //Here For loop is needed instead of foreach as we are using await function below
                        for (
                            let i = 0;
                            i < data?.relationships?.instances?.data.length;
                            i++
                        ) {
                            const instance = data?.relationships?.instances?.data[i]
                            let instanceDataObj = {
                                instanceId: "",
                                instanceName: "",
                                availableSeats: "-1",
                                state: "",
                                totalPurchasedEnrolledSeats: 0,
                                instanceResourceData: {},
                                classroomCount: 0,
                                instanceProgress: null,
                                modules: [],
                                instance: [],
                            }

                            const instanceObj = included.find(
                                (i) => instance.id === i.id,
                            )


                            let checkState = instanceObj?.attributes?.state
                            if ((checkState === "Retired") && !(instanceObj.relationships?.hasOwnProperty("enrollment"))) {
                                // setDisabaledEnrollBtn(true)
                            } else {
                                setDisabaledEnrollBtn(false)
                            }

                            // if (instanceObj && instanceObj?.attributes?.state !== "Retired") {
                            if (instanceObj) {
                                instanceDataObj.instanceId = instanceObj?.id
                                // below code for the course and module progress store
                                if (instanceObj?.relationships?.enrollment) {
                                    const enrolledInstance =
                                        instanceObj?.relationships?.enrollment?.data?.id

                                    const getEnrollData = included?.find(
                                        (e) => e?.id == enrolledInstance,
                                    )

                                    const courseInstanceProgress =
                                        getEnrollData?.attributes?.progressPercent

                                    instanceDataObj.instanceProgress = courseInstanceProgress

                                }
                                instanceDataObj.instanceName =
                                    instanceObj?.attributes?.localizedMetadata?.find(
                                        (e) => {
                                            return e?.locale === "en-US"
                                        },
                                    ).name

                                instanceDataObj.state = instanceObj?.attributes?.state
                                /////////////////////// to set all here 
                                if (loType == "course") {
                                    const tempModuleData = getModulesData(instanceObj, included)
                                    instanceDataObj.classroomCount = tempModuleData.classroomCount
                                    instanceDataObj.instanceResourceData = tempModuleData.loResourceDataObj
                                    instanceDataObj.modules = tempModuleData.moduleData

                                } else if (loType == "learningProgram") {
                                    //With normal flow
                                    //instanceObj?.relationships?.subLoInstances?.data?.forEach(
                                    // With ordering on courses
                                    // for lp we create course for object 
                                    lpLoDataInOrderArray?.forEach((loInstance) => {
                                        let courseDataObj = {
                                            id: "",
                                            name: "",
                                            instanceId: "",
                                            description: "",
                                            loType: "",
                                            duration: 0,
                                            instructorName: [],
                                            modules: [],
                                            progressPercent: null,
                                            hasPassed: null,
                                            imageUrl: "",
                                            authorNames: null,
                                            stateOfCourseOrLP: "",
                                            sessionRecording: "",
                                            classroomData: ""
                                        }

                                        //TODO- For sections
                                        const loObj = included.find(
                                            (i) => loInstance.id === i.id,
                                        )
                                        const loInstanceIdOfCourse = loObj?.relationships?.instances?.data[0]?.id
                                        const loEnrollmentIdOfCourse = loObj?.relationships?.enrollment?.data?.id
                                        const cObj = included.find(
                                            (i) => loInstanceIdOfCourse === i.id,
                                        )
                                        if (cObj?.relationships?.enrollment) {
                                            const enrolledCourseData = included.find(
                                                (i) => cObj?.relationships?.enrollment?.data?.id === i.id,
                                            )
                                            courseDataObj.hasPassed = enrolledCourseData?.attributes?.hasPassed
                                            courseDataObj.progressPercent = enrolledCourseData?.attributes?.progressPercent
                                            courseDataObj.stateOfCourseOrLP = enrolledCourseData?.attributes?.state
                                            courseDataObj.loResourceCompletionCount = loObj?.attributes?.loResourceCompletionCount
                                        } else if (loEnrollmentIdOfCourse) {
                                            const enrolledCourseData = included.find(
                                                (i) => loEnrollmentIdOfCourse === i.id,
                                            )
                                            courseDataObj.hasPassed = enrolledCourseData?.attributes?.hasPassed
                                            courseDataObj.progressPercent = enrolledCourseData?.attributes?.progressPercent
                                            courseDataObj.stateOfCourseOrLP = enrolledCourseData?.attributes?.state
                                            courseDataObj.loResourceCompletionCount = loObj?.attributes?.loResourceCompletionCount
                                        }
                                        const {
                                            duration,
                                            loType,
                                            localizedMetadata,
                                            authorNames,
                                            isSubLoOrderEnforced,
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                        } = loObj?.attributes

                                        courseDataObj.id = loObj?.id
                                        const localize = localizedMetadata?.find(
                                            (e) => {
                                                return e?.locale === "en-US"
                                            },
                                        )
                                        courseDataObj.name = localize?.name
                                        courseDataObj.instanceId = loObj?.id
                                        courseDataObj.description = localize?.description
                                        courseDataObj.loType = loType
                                        courseDataObj.duration = duration
                                        courseDataObj.imageUrl = loObj?.attributes?.imageUrl
                                        courseDataObj.authorNames = authorNames
                                        courseDataObj.isSubLoOrderEnforced = isSubLoOrderEnforced
                                        courseDataObj.virtualrecording = loObj?.attributes?.sessionRecordingInfo ? loObj?.attributes?.sessionRecordingInfo.map((res) => {
                                            return {
                                                name: res.name,
                                                url: res.url
                                            }
                                        }) : [];
                                        const tempModuleObjLP = getModulesData(cObj, included)
                                        courseDataObj.course = tempModuleObjLP?.moduleData
                                        // courseDataObj.loResourceCompletionCount = loObj?.attributes?.loResourceCompletionCount ? loObj?.attributes?.loResourceCompletionCount : tempModuleObjLP?.moduleData?.filter(item=>item.loResourceType ==="Content")?.length
                                        courseDataObj.learningObjectInstanceModuleLength = tempModuleObjLP?.moduleData?.filter(item => item.loResourceType === "Content")?.length
                                        const classroomModulesinCourse =
                                            tempModuleObjLP.moduleData?.filter((moduleItem) => {
                                                return moduleItem?.contentType === "Classroom"
                                            })

                                        if (classroomModulesinCourse.length > 0) {
                                            instanceDataObj.classroomCount =
                                                classroomModulesinCourse?.length
                                        }
                                        const mandatoryModulesCount = courseDataObj?.course?.filter(module => module.mandatory === true)?.length;
                                        courseDataObj.mandatoryModulesCount = mandatoryModulesCount
                                        // courseDataObj.loResourceCompletionCount = mandatoryModulesCount ? mandatoryModulesCount : (loObj?.attributes?.loResourceCompletionCount ? loObj?.attributes?.loResourceCompletionCount : tempModuleObjLP?.moduleData?.filter(item=>item.loResourceType ==="Content")?.length);
                                        courseDataObj.loResourceCompletionCount = loObj?.attributes?.loResourceCompletionCount;
                                        instanceDataObj.instance.push(courseDataObj)
                                        setcoreContentItemModule(mandatoryModulesCount)
                                    })
                                }
                                instancesArr.push(instanceDataObj)
                            }
                        }
                        setCourseInstances(instancesArr)
                        const newTestOutData = instancesArr[selectedInstance]?.modules?.filter(item => item.loResourceType !== "Test Out")
                        setCourseInstancesWithTestOut(newTestOutData)
                    }
                    let loe = included?.find((e) => {
                        return e?.type === "learningObjectInstanceEnrollment" && e.id.toLowerCase().startsWith(data.id.toLowerCase());
                    });
                    // L1 feedback button for learning path
                    let instanceID = loe?.relationships?.loInstance?.data?.id
                    // eslint-disable-next-line no-prototype-builtins
                    if (loe?.relationships?.hasOwnProperty("loInstance")) {
                        let loInstanceData = included?.find((e) => {
                            return e.id === instanceID;
                        });
                        // eslint-disable-next-line no-prototype-builtins
                        const containsL1FeedbackInfo = loInstanceData?.relationships?.hasOwnProperty("l1FeedbackInfo");
                        setContainsL1FeedbackInfo(containsL1FeedbackInfo)
                    }
                    setLearningObjectInstanceEnrollment(loe);
                    setRatting(loe?.attributes?.rating)
                    setRate(loe?.attributes?.rating)

                    let getAllInstanceNameAndCourseIds = await getLerningObjectCourses(course_id)
                    setLearningObject(getAllInstanceNameAndCourseIds)

                    //ToDo- course completion for learning program
                    let completionDeadlineDate = "";
                    let loInstanceObj = "";

                    if (loe) {
                        const enrollmentId = loe?.id;
                        loInstanceObj = included.find((e) => e.id === enrollmentId.substring(0, enrollmentId.lastIndexOf("_")) && e.type === "learningObjectInstance");
                    }
                    else {
                        loInstanceObj = included.find((e) => e.id === data?.relationships?.instances?.data[0]?.id && e.type === "learningObjectInstance");
                    }

                    if (loe?.attributes?.completionDeadline && new Date(loe?.attributes?.completionDeadline).toString().toLowerCase() !== "invalid date") {
                        completionDeadlineDate = loe?.attributes?.completionDeadline
                    } else if (loInstanceObj?.attributes?.completionDeadline && new Date(loInstanceObj?.attributes?.completionDeadline).toString().toLowerCase() !== "invalid date") {
                        completionDeadlineDate = loInstanceObj?.attributes?.completionDeadline
                    }
                    setCourseCompleteDate(completionDeadlineDate)
                }
                ///////////////////////////////////// certification 
                else if (data?.attributes?.loType === "certification") {
                    let instancesArr = [];
                    const loType = data?.attributes?.loType
                    let lpLoDataInOrderArray = []
                    if (loType == "certification") {
                        data?.relationships?.subLOs?.data?.forEach((loData) => {
                            lpLoDataInOrderArray.push(loData.id)
                        })
                    }
                    if (data?.relationships?.instances?.data?.length > 0) {
                        //Here For loop is needed instead of foreach as we are using await function below
                        for (
                            let i = 0;
                            i < data?.relationships?.instances?.data.length;
                            i++
                        ) {
                            const instance = data?.relationships?.instances?.data[i]
                            let instanceDataObj = {
                                instanceId: "",
                                instanceName: "",
                                availableSeats: "-1",
                                totalPurchasedEnrolledSeats: 0,
                                instanceResourceData: {},
                                classroomCount: 0,
                                instanceProgress: null,
                                modules: [],
                                instance: [],
                            }
                            const instanceObj = included.find(
                                (i) => instance.id === i.id,
                            )
                            setIsLPInstanceList(true);
                            // ----------------------------------------------
                            //TODO - for  certification Retired course should not enroll functionality
                            let checkState = instanceObj?.attributes?.state
                            // eslint-disable-next-line no-prototype-builtins
                            if ((checkState === "Retired") && !(instanceObj.relationships?.hasOwnProperty("enrollment"))) {
                                setDisabaledEnrollBtn(true)
                            } else {
                                setDisabaledEnrollBtn(false)
                            }
                            // -------------------------------------------------
                            if (instanceObj) {
                                instanceDataObj.instanceId = instanceObj?.id
                                if (instanceObj?.relationships?.enrollment) {
                                    const enrolledInstance =
                                        instanceObj?.relationships?.enrollment?.data?.id

                                    const getEnrollData = included?.find(
                                        (e) => e?.id == enrolledInstance,
                                    )

                                    const courseInstanceProgress =
                                        getEnrollData?.attributes?.progressPercent

                                    instanceDataObj.instanceProgress = courseInstanceProgress

                                }
                                instanceDataObj.instanceName =
                                    instanceObj?.attributes?.localizedMetadata?.find(
                                        (e) => {
                                            return e?.locale === "en-US"
                                        },
                                    ).name

                                if (loType == "course") {
                                    const tempModuleData = getModulesData(instanceObj, included)
                                    instanceDataObj.classroomCount = tempModuleData.classroomCount
                                    instanceDataObj.instanceResourceData =
                                        tempModuleData.loResourceDataObj
                                    instanceDataObj.modules = tempModuleData.moduleData

                                } else if (loType == "certification") {
                                    lpLoDataInOrderArray?.forEach((loInstance) => {
                                        let courseDataObj = {
                                            id: "",
                                            name: "",
                                            instanceId: "",
                                            description: "",
                                            loType: "",
                                            duration: 0,
                                            instructorName: [],
                                            modules: [],
                                            progressPercent: null,
                                            hasPassed: null,
                                            imageUrl: "",
                                            authorNames: null,
                                            stateOfCourseOrLP: null,
                                            sessionRecording: []
                                        }
                                        //TODO- For sections
                                        const loObj = included.find(
                                            (i) => loInstance === i.id,
                                        )
                                        
                                        const loInstanceIdOfCourse = loObj?.relationships?.instances?.data[0]?.id
                                        const loEnrollmentIdOfCourse = loObj?.relationships?.enrollment?.data?.id
                                        const cObj = included.find(
                                            (i) => loInstanceIdOfCourse === i.id,
                                        )

                                        if (cObj?.relationships?.enrollment) {
                                            const enrolledCourseData = included.find(
                                                (i) => cObj?.relationships?.enrollment?.data?.id === i.id,
                                            )
                                            courseDataObj.hasPassed = enrolledCourseData?.attributes?.hasPassed
                                            courseDataObj.progressPercent = enrolledCourseData?.attributes?.progressPercent
                                            courseDataObj.stateOfCourseOrLP = enrolledCourseData?.attributes?.state

                                        } else if (loEnrollmentIdOfCourse) {
                                            const enrolledCourseData = included.find(
                                                (i) => loEnrollmentIdOfCourse === i.id,
                                            )
                                            courseDataObj.hasPassed = enrolledCourseData?.attributes?.hasPassed
                                            courseDataObj.progressPercent = enrolledCourseData?.attributes?.progressPercent
                                            courseDataObj.stateOfCourseOrLP = enrolledCourseData?.attributes?.state
                                        }
                                        const {
                                            duration,
                                            loType,
                                            localizedMetadata,
                                            authorNames,
                                            mandatory,
                                            isSubLoOrderEnforced
                                            // eslint-disable-next-line no-unsafe-optional-chaining
                                        } = loObj?.attributes
                                        // let sessionRecordingInfo= [
                                        //     {
                                        //         duration: "3421",
                                        //         endtime: "1690902082411",
                                        //         name: "Escalation Management-20230801_183401-Meeting Recording.mp4",
                                        //         startTime: "1690898661411",
                                        //         url: "https://adobe-my.sharepoint.com/:v:/p/shivanginia/EdBGnwJUrFZNlK4PfYqfsy4BI9x6424hUq6LSEc_rgL43g?cp_oauth_jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwIjoidXJsIiwicmFuZCI6ImI2ZTMzMzIxNDU4MzYxYjFhNTU0ZTY4NzJjYTJlNGQ4IiwidiI6IjEiLCJvcmlnaW5hbFVybEhhc2giOiI3MzU3ZGFiNGI1YWFjNTk3M2FjMzY3YmExNTExZTBiMTk3MGZlNzk2IiwiZXhwIjoxNzEwMzI0NTg0LCJyaWQiOiIxNTMyMTA1MyIsImlhdCI6MTcwOTcxOTc4NCwiY2lkIjoiMjIyNCJ9.1xX2oC1IezLB-T9r2wsegZo5v1YfxBy4KiVgleiTE18"
                                        //     }
                                        // ]
                                        courseDataObj.id = loObj.id
                                        const localize = localizedMetadata?.find(
                                            (e) => {
                                                return e?.locale === "en-US"
                                            },
                                        )
                                        courseDataObj.name = localize?.name
                                        courseDataObj.instanceId = loObj?.id
                                        courseDataObj.description = localize?.description
                                        courseDataObj.loType = loType
                                        courseDataObj.duration = duration
                                        courseDataObj.imageUrl = loObj?.attributes?.imageUrl
                                        courseDataObj.authorNames = authorNames
                                        courseDataObj.mandatory = mandatory
                                        courseDataObj.isSubLoOrderEnforced = isSubLoOrderEnforced
                                        courseDataObj.sessionRecording = loObj?.attributes?.sessionRecordingInfo ? loObj?.attributes?.sessionRecordingInfo.map((res) => {
                                            return {
                                                name: res.name,
                                                url: res.url
                                            }
                                        }) : [];

                                        // courseDataObj.loResourceCompletionCount =loObj?.attributes?.loResourceCompletionCount ?  loObj?.attributes?.loResourceCompletionCount : tempModuleObjLP?.moduleData?.filter(item=>item.loResourceType ==="Content")?.length ;

                                        const tempModuleObjLP = getModulesData(cObj, included)
                                        courseDataObj.course = tempModuleObjLP?.moduleData
                                        courseDataObj.learningObjectInstanceModuleLength = tempModuleObjLP?.moduleData?.filter(item => item.loResourceType === "Content")?.length
                                        const classroomModulesinCourse =
                                            tempModuleObjLP.moduleData?.filter((moduleItem) => {
                                                return moduleItem?.contentType === "Classroom"
                                            })

                                        if (classroomModulesinCourse.length > 0) {
                                            instanceDataObj.classroomCount =
                                                classroomModulesinCourse.length
                                        }
                                        const mandatoryModulesCount = courseDataObj.course.filter(module => module.mandatory === true).length;
                                        courseDataObj.mandatoryModulesCount = mandatoryModulesCount
                                        // courseDataObj.loResourceCompletionCount =mandatoryModulesCount ? mandatoryModulesCount : (loObj?.attributes?.loResourceCompletionCount ?  loObj?.attributes?.loResourceCompletionCount : tempModuleObjLP?.moduleData?.filter(item=>item.loResourceType ==="Content")?.length) ;
                                        courseDataObj.loResourceCompletionCount = loObj?.attributes?.loResourceCompletionCount;
                                        instanceDataObj.instance.push(courseDataObj)
                                    })
                                }
                                instanceDataObj.loResourceCompletionCount = data?.attributes?.loResourceCompletionCount
                                instanceDataObj.learningObjectInstanceModuleLength = instanceDataObj.instance.length
                                instancesArr.push(instanceDataObj)
                            }
                        }
                        setCourseInstances(instancesArr)
                        const newTestOutData = instancesArr[selectedInstance]?.modules?.filter(item => item.loResourceType !== "Test Out")
                        setCourseInstancesWithTestOut(newTestOutData)
                    }
                    let loe = included.find((e) => {
                        return e.type === "learningObjectInstanceEnrollment" && e.id.toLowerCase().startsWith(data.id.toLowerCase());
                    });
                    setLearningObjectInstanceEnrollment(loe);
                    setRatting(loe?.attributes?.rating)
                    setRate(loe?.attributes?.rating)

                    let getAllInstanceNameAndCourseIds = await getLerningObjectCourses(course_id)
                    setLearningObject(getAllInstanceNameAndCourseIds)

                    //ToDo- course completion for learning program
                    let completionDeadlineDate = "";
                    let loInstanceObj = "";

                    if (loe) {
                        const enrollmentId = loe?.id;
                        loInstanceObj = included.find((e) => e.id === enrollmentId.substring(0, enrollmentId.lastIndexOf("_")) && e.type === "learningObjectInstance");
                    }
                    else {
                        loInstanceObj = included.find((e) => e.id === data?.relationships?.instances?.data[0]?.id && e.type === "learningObjectInstance");
                    }

                    if (loe?.attributes?.completionDeadline && new Date(loe?.attributes?.completionDeadline).toString().toLowerCase() !== "invalid date") {
                        completionDeadlineDate = loe?.attributes?.completionDeadline
                    } else if (loInstanceObj?.attributes?.completionDeadline && new Date(loInstanceObj?.attributes?.completionDeadline).toString().toLowerCase() !== "invalid date") {
                        completionDeadlineDate = loInstanceObj?.attributes?.completionDeadline
                    }
                    setCourseCompleteDate(completionDeadlineDate)
                }
                ///////////////////////////////////// course 
                else {
                    //------COURSES (Multi and single instances)-----
                    // get all job aids
                    let jobAidsArray = await getAllJobAids(course_id);
                    if(jobAidsArray != null) setJobAids(jobAidsArray);

                    let learningObjectInstances = included?.filter((e) => {
                        return e?.type === "learningObjectInstance";
                    });
                    ////////////////////////////////////////

                    // TODO - for Resources course 
                    let resourceDataArrayCourse = [];
                    if (data?.relationships?.supplementaryResources?.data?.length > 0) {
                        data.relationships.supplementaryResources.data.forEach((item) => {
                            resourceDataArrayCourse.push(item.id);
                        });
                    }

                    let resourcesDataAid = included?.filter(item => resourceDataArrayCourse?.includes(item.id));

                    let resourcesDataArray = [];
                    resourcesDataAid?.forEach((item) => {
                        if (item.attributes?.name && item.attributes?.location) {
                            resourcesDataArray.push({
                                name: item.attributes.name,
                                location: item.attributes.location
                            });
                        }
                    });
                    setResourceAids(resourcesDataArray);
                    // end resource data

                    if (learningObjectInstances.length > 0) {
                        let checkInstance = learningObjectInstances?.filter(item => !item.id.startsWith("jobAid"))
                        setDisabaledEnrollBtn(true)
                        checkInstance?.map((it) => {
                            let temp = it?.attributes?.state

                            // eslint-disable-next-line no-prototype-builtins
                            if ((temp === "Retired") && !(learningObjectInstances?.relationships?.hasOwnProperty("enrollment"))) {
                                // setDisabaledEnrollBtn(true)
                            } else {
                                setDisabaledEnrollBtn(false)
                            }
                        })


                        const url = window.location.href;
                        const courseStringToCheck = "course:";

                        // Use a regular expression to count the number of occurrences of the course string in the URL
                        const courseStringCount = (url.match(new RegExp(courseStringToCheck, "g")) || []).length;

                        if (courseStringCount === 1) {
                            if (checkInstance.length > 1) {
                                setIsLPInstanceList(false)
                            } else {
                                setIsLPInstanceList(true)
                            }
                        }
                        let tempCourseInstances = [];
                        let totalCoreContentCompleted = 0;

                        let loe = included?.find((e) => {
                            return e.type === "learningObjectInstanceEnrollment" && e.id?.toLowerCase().startsWith(data?.id?.toLowerCase());
                        });
                        let instanceid = loe?.relationships?.loInstance?.data?.id
                        // eslint-disable-next-line no-prototype-builtins
                        if (loe?.relationships?.hasOwnProperty("loInstance")) {
                            let loInstanceData = included?.find((e) => {
                                return e.id === instanceid;
                            });

                            // eslint-disable-next-line no-prototype-builtins
                            const containsL1FeedbackInfo = loInstanceData?.relationships?.hasOwnProperty("l1FeedbackInfo");
                            setContainsL1FeedbackInfo(containsL1FeedbackInfo)
                        }

                        setLearningObjectInstanceEnrollment(loe);
                        setRatting(loe?.attributes?.rating)
                        setRate(loe?.attributes?.rating)
                        let timeout = included?.filter((e) => {
                            return e?.attributes?.loResourceType === "Test Out";
                        });
                        setTimeout(timeout)
                        learningObjectInstances?.filter(item => !item.id.startsWith("jobAid"))?.forEach((learningObjectInstance) => {
                            let tempCourseInstanceObject = {
                                instanceName: "",
                                instanceId: "",
                                seatLimit: "",
                                state: "",
                                instanceResourceData: {},
                                modules: [],
                                classroomCount: 0,
                                shrinivas: ""
                            };

                            let instancelocalizedMetadata =
                                learningObjectInstance?.attributes?.localizedMetadata?.find((e) => {
                                    return e?.locale === "en-US";
                                });
                            let learningObjectInstanceId = learningObjectInstance?.id
                            let tempCoreContent = [];
                            let tempLoResourceObject = {
                                contentType: "",
                                instructorName: "",
                                location: "",
                                dateStart: "",
                                completionDeadline: ""
                            };
                            let classroomCount = 0;

                            let hasEnrolled = false;

                            learningObjectInstance?.relationships?.loResources?.data?.forEach(
                                (learningObjectResourceData) => {
                                    let tempObject = {
                                        id: "",
                                        duration: 0,
                                        name: "",
                                        contentType: "",
                                        classroomCount: 0,
                                        classroomData: {},
                                        loResourceType: "",
                                    };
                                    let learningObjectResource = included?.find((e) => {
                                        return e?.id === learningObjectResourceData?.id;
                                    });
                                    let localizedMetadata =
                                        learningObjectResource?.attributes?.localizedMetadata?.find((e) => {
                                            return e?.locale === "en-US";
                                        });
                                    //
                                    let moduleCountArr = [];
                                    if (learningObjectResource?.attributes?.mandatory === true) {
                                        moduleCountArr.push(true)
                                    }
                                    let loResourceData = included?.find((e) => {
                                        return (
                                            e?.id === learningObjectResource?.relationships?.resources?.data[0]?.id
                                        );

                                    })
                                    let learningObjectResourceGrade = included.find((e) => {
                                        if (
                                            e.type === "learningObjectResourceGrade" && e.relationships?.loResource?.data?.id === learningObjectResource?.id
                                        ) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    });
                                    //  ToDo
                                    let completionDeadlineDate = "";
                                    let loInstanceObj = "";
                                    if (loe) {
                                        const enrollmentId = loe?.id;
                                        loInstanceObj = included.find((e) => e.id === enrollmentId.substring(0, enrollmentId.lastIndexOf("_")) && e.type === "learningObjectInstance");
                                    }
                                    else {
                                        loInstanceObj = included.find((e) => e.id === data?.relationships?.instances?.data[0]?.id && e.type === "learningObjectInstance");
                                    }

                                    if (loe?.attributes?.completionDeadline && new Date(loe?.attributes?.completionDeadline).toString().toLowerCase() !== "invalid date") {
                                        completionDeadlineDate = loe?.attributes?.completionDeadline
                                    } else if (loInstanceObj?.attributes?.completionDeadline && new Date(loInstanceObj?.attributes?.completionDeadline).toString().toLowerCase() !== "invalid date") {
                                        completionDeadlineDate = loInstanceObj?.attributes?.completionDeadline
                                    }
                                    /*let learningObjectCourseCompletion = included.find((e) => {
                    if (
                      e.type === "learningObjectInstance" 
                      // && e.relationships?.loResource?.data?.id === learningObjectResource?.id
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  });*/


                                    setCourseCompleteDate(completionDeadlineDate)

                                    if (learningObjectResourceGrade?.attributes.hasPassed) {
                                        totalCoreContentCompleted += 1;
                                    }
                                    setCoreContentCompleted(totalCoreContentCompleted);

                                    if ((loResourceData?.attributes?.contentType == "Classroom") || (loResourceData?.attributes?.contentType == "Virtual Classroom")) {
                                        let instructorName = "";
                                        loResourceData?.attributes?.instructorNames?.forEach(
                                            (instructorNameVal) => {
                                                if (instructorName === "") {
                                                    instructorName = instructorNameVal;
                                                } else {
                                                    instructorName = instructorName + ", " + instructorNameVal;
                                                }
                                            }
                                        )
                                        classroomCount++;
                                        const obj = {
                                            contentType: loResourceData?.attributes?.contentType,
                                            completionDeadline: loResourceData?.attributes?.completionDeadline,
                                            dateStart: loResourceData?.attributes?.dateStart,
                                            instructorName: instructorName,
                                            roomLocation: loResourceData?.attributes?.roomLocation ? loResourceData?.attributes?.roomLocation : null,
                                            location: loResourceData?.attributes?.location ? loResourceData?.attributes?.location :
                                                loResourceData?.attributes?.roomLocation ? loResourceData?.attributes?.roomLocation : null,
                                            seatLimit: loResourceData?.attributes?.seatLimit,
                                        }
                                        tempObject.classroomData = { ...obj };
                                    }
                                    tempObject.contentType = loResourceData?.attributes?.contentType
                                    tempObject.id = learningObjectResourceData.id;
                                    tempObject.duration = loResourceData?.attributes?.authorDesiredDuration || loResourceData?.attributes?.desiredDuration;
                                    tempObject.hasPassed = learningObjectResourceGrade?.attributes.hasPassed || false;
                                    tempObject.progressPercent = learningObjectResourceGrade?.attributes.progressPercent || 0;
                                    tempObject.score = learningObjectResourceGrade?.attributes.score || 0;
                                    tempObject.name = localizedMetadata.name;
                                    tempObject.description = localizedMetadata.description;
                                    tempObject.multipleAttempt = learningObjectResource.attributes.multipleAttempt;
                                    tempLoResourceObject.seatLimit = loResourceData?.attributes?.seatLimit;
                                    tempObject.loResourceType = learningObjectResource?.attributes?.loResourceType;
                                    tempObject.checkList = learningObjectResource?.attributes?.checklistEvaluationStatus || "";
                                    tempObject.resourceType = learningObjectResource?.attributes?.resourceType || "";
                                    tempObject.resourceSubType = learningObjectResource?.attributes?.resourceSubType || "";
                                    tempObject.submissionState = learningObjectResource?.attributes?.submissionState;
                                    tempObject.mandatory = learningObjectResource?.attributes?.mandatory;
                                    tempObject.submissionUrl = learningObjectResource?.attributes?.submissionUrl || "";
                                    tempObject.submissionEnabled = learningObjectResource?.attributes?.submissionEnabled;
                                    tempObject.loResourceCompletionCount = data?.attributes?.loResourceCompletionCount;
                                    tempObject.learningObjectInstanceModuleLength = learningObjectInstance?.relationships?.loResources?.data?.length;
                                    tempObject.loResourceType = learningObjectResource?.attributes?.loResourceType;
                                    tempObject.learnerAttemptInfo = learningObjectResource?.attributes?.learnerAttemptInfo;
                                    tempObject.sessionRecording = learningObjectResource?.attributes?.sessionRecordingInfo ? learningObjectResource?.attributes?.sessionRecordingInfo.map((res) => {
                                        return {
                                            name: res.name,
                                            url: res.url
                                        }
                                    }) : [];

                                    if (learningObjectResourceGrade) hasEnrolled = true;
                                    tempCoreContent.push(tempObject);
                                }

                            );

                            const extractedData = {};
                            // Loop through the array and extract the values
                            tempCoreContent.forEach(course => {
                                const courseId = course.id;
                                const loResourceCompletionCount = course.loResourceCompletionCount;
                                const learningObjectInstanceModuleLength = course?.learningObjectInstanceModuleLength;

                                // Store the extracted values in the extractedData object using the courseId as the key
                                extractedData[courseId] = {
                                    loResourceCompletionCount,
                                    learningObjectInstanceModuleLength
                                };
                            });
                            setCompletionCount(extractedData)
                            setCoreContent(tempCoreContent);
                            tempCourseInstanceObject.instanceName = instancelocalizedMetadata?.name;
                            tempCourseInstanceObject.seatLimit = learningObjectInstance?.attributes?.seatLimit;
                            tempCourseInstanceObject.modules = tempCoreContent;
                            tempCourseInstanceObject.instanceId = learningObjectInstanceId;
                            tempCourseInstanceObject.classroomCount = classroomCount
                            tempCourseInstanceObject.hasEnrolled = hasEnrolled;
                            tempCourseInstanceObject.loResourceCompletionCount = tempCoreContent?.filter(module => module.mandatory === true)?.length ? tempCoreContent?.filter(module => module.mandatory === true)?.length : (data?.attributes?.loResourceCompletionCount ? data?.attributes?.loResourceCompletionCount : tempCoreContent?.filter(item => item.loResourceType === "Content")?.length);
                            tempCourseInstanceObject.learningObjectInstanceModuleLength = tempCoreContent?.filter(item => item.loResourceType === "Content")?.length
                            tempCourseInstanceObject.state = learningObjectInstance?.attributes?.state;
                            if (classroomCount == 1) {
                                tempCourseInstanceObject.instanceResourceData = tempLoResourceObject;
                            }
                            tempCourseInstances.push(tempCourseInstanceObject);

                        });
                        setCourseInstances(tempCourseInstances);
                        const mandatoryModulesCount = tempCourseInstances[0]?.modules?.filter(module => module.mandatory === true)?.length;
                        setcoreContentItemModule(mandatoryModulesCount)
                        const newTestOutData = tempCourseInstances[selectedInstance]?.modules?.filter(item => item.loResourceType !== "Test Out")
                        setCourseInstancesWithTestOut(newTestOutData)
                    }
                }

                // if(courseCompleteDate?.attributes?.completionDeadline && courseCompleteDate?.attributes?.completionDeadline.toString().toLowerCase() !== "invalid date"){
                //   completionDeadline= courseCompleteDate?.attributes?.completionDeadline;
                //   }
                // -------------------------prerequisiteLOs -------------------------
                if (data.relationships && data.relationships.prerequisiteLOs && data.relationships.prerequisiteLOs.data && data.relationships.prerequisiteLOs.data.length) {
                    let prerequisiteCourse = data.relationships.prerequisiteLOs.data.map(async (prerequisite) => {
                        let prerequisiteData = await getprerequisiteLOsCouses(prerequisite.id);
                        let filteredConstraints = [];
                        if (data.attributes.loType === "learningProgram") {
                            filteredConstraints = data?.attributes?.prequisiteConstraints?.filter((constraint) => {
                                return constraint?.mandatory === true
                                    && constraint?.prerequisiteLOId === prerequisite?.id;
                            });
                        }
                        const courseMainDetails = included?.find(item => item.id === prerequisite.id)
                        if (courseMainDetails) {
                            let { attributes, id } = courseMainDetails;
                            let temp = {}
                            const authorNames = attributes?.authorNames?.join(", ");
                            temp.instanceName = attributes?.localizedMetadata[0]?.name;
                            temp.description = attributes?.localizedMetadata[0]?.overview;
                            temp.richTextOverview = attributes?.localizedMetadata[0]?.richTextOverview;
                            temp.loFormat = attributes?.loFormat;
                            temp.instanceId = id;
                            temp.loType = attributes?.loType;
                            temp.authorNames = authorNames;
                            temp.isMandatoryPrerequisite = filteredConstraints?.length > 0 ? filteredConstraints[0]?.mandatory || false : false;
                            temp.hasPassedForPrerequisite = prerequisiteData?.progressPercentForPre?.attributes?.hasPassed;
                            temp.progressPercentForPrerequisite = prerequisiteData?.progressPercentForPre?.attributes?.progressPercent;
                            const obj = { ...temp, course: prerequisiteData?.prerequisite }
                            return obj
                        }
                    })

                    prerequisiteCourse = await Promise.all(prerequisiteCourse);
                    setShowLoader(false)
                    setPrerequisiteCourse(prerequisiteCourse)
                } else {
                    setShowLoader(false);
                }

                if (data?.relationships?.prerequisiteLOs?.data?.length > 0) {
                    let { data: pdata, included: pincluded } = await apis.getLearningObject(data.relationships.prerequisiteLOs.data[0]?.id);
                    const mainObject = {};

                    const { localizedMetadata, ...rest } = { ...data?.attributes };

                    const tempOfMainFile = {
                        ...rest,
                        name: localizedMetadata[0]?.name ?? " ",
                        description: localizedMetadata[0]?.description ?? " ",
                        locale: localizedMetadata[0]?.locale ?? " ",
                    }
                    mainObject.attributes = tempOfMainFile;
                    const instanceTemp = []
                    pdata?.relationships?.instances?.data?.map((instance) => {
                        const courseMainDetails = pincluded?.find(item => item.id === instance.id)

                        const tempInstance = {
                            name: courseMainDetails?.attributes?.localizedMetadata[0]?.name
                        }
                        const instanceData = []
                        courseMainDetails?.relationships?.loResources?.data?.map((course) => {
                            const eachCourse = pincluded?.find(item => item.id === course.id)

                            eachCourse?.relationships?.resources?.data?.map((resource) => {
                                const resourceData = pincluded?.find(item => item.id === resource.id)
                                instanceData.push(resourceData?.attributes ?? {})
                            })
                        })

                        tempInstance.course = instanceData
                        instanceTemp.push(tempInstance)
                    })

                    mainObject.instance = instanceTemp;
                }
            }
        } catch (error) {
            console.log(error)
            setErrorMsg(true)
            setShowLoader(false);
        }
    };

    const enrollmentCount = async (data) => {
        let totalCount = 0;
        data?.relationships?.instances?.data.map(async (item) => {

            const result = await apis.getEnrollmentCount(
                data.id,
                item.id
            );
            totalCount = totalCount + result?.data?.attributes?.enrollmentCount
            setEnrollmentCounts(totalCount);
            if (instance_id && item?.id === instance_id) {
                setEnrollmenInstanceCounts(result?.data?.attributes?.enrollmentCount);
            }
        });
    };

    useEffect(() => {
        if (courseDetails && courseDetails?.attributes?.loType !== "jobAid") {
            enrollmentCount(courseDetails)
        }
    }, [courseDetails])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setIsDivVisible(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => { document.removeEventListener("click", handleClickOutside); };
    }, []);

    async function getprerequisiteLOsCouses(courseId) {
        try {
            if (courseId) {
                let { included } = await apis.getLearningObject(courseId);
                const progressPercentForPre = included?.find(
                    (e) => e.type === "learningObjectInstanceEnrollment" && e.id?.toLowerCase().startsWith(courseId?.toLowerCase())
                );

                let prerequisite = included?.filter((e) => {
                    return e?.type === "resource";
                });
                return { progressPercentForPre: progressPercentForPre, prerequisite: prerequisite };
            }
        }
        catch (e) {
            console.warn(e)
        }
    }

    const progressPercent = courseDetailsIncluded?.find(
        (e) => e.type === "learningObjectInstanceEnrollment" && e.id?.toLowerCase().startsWith(courseDetails?.id?.toLowerCase())
    )?.attributes?.progressPercent;

    const playCourse = (cid, mid) => {
        // if(mid.includes(cid)){
        let learningObjectInstanceEnrollment = courseDetailsIncluded?.find(
            (e) => e.type === "learningObjectInstanceEnrollment"
        );
        if (!learningObjectInstanceEnrollment) {
            NotificationManager.warning(t("player-warning"));
            return;
        }
        navigate(`/course-player/${cid}`, {
            state: {
                cid,
                mid,
                goBackUrl: location?.pathname,
                crumbs
            }
        });
        // }
    };

    const enrollToCourse = async (cid, ciid) => {
        try {
            await apis.setEnrollment(cid, ciid);
            window.location.reload();
        } catch (error) {
            console.warn(error);
            return;
        }
    };

    // api call for unenroll
    const unenrollToCourse = async (id) => {
        setShowModall(true)
        setunenroolId(id)

    };

    const unenrollApi = async (id) => {
        try {
            const res = await apis.unEnroll(id);
            window.location.reload();
        } catch (error) {
            console.warn(error);
            return;
        }
    }

    const handleOkDisplayMessageOne = async () => {
        setShowModall(false);
        unenrollApi(unenroolId)

    };

    const handleCloseDisplayMessageOne = () => {
        setShowModall(false);
    };


    const handleButtonClickWithEnroll = async (courseId, courseInstanceId) => {
        try {
            await apis.setEnrollment(courseId, courseInstanceId);
            handleButtonJobAidClick();
            window.location.reload();
        } catch (error) {
            console.warn(error);
            return;
        }
    }

    const handleButtonJobAidClick = () => {
        window.open(jobAids[0]?.location, "_blank");
    };

    const startUi = (id) => {
        let loID = instance_id
        if (!instance_id) {
            loID = courseDetails.relationships.instances.data[0].id
        }

        let learningObjectInstanceEnrollment = courseDetailsIncluded?.find(
            (e) => e.type === "learningObjectInstanceEnrollment" && e.id?.toLowerCase().startsWith(courseDetails?.id?.toLowerCase())
        );
        let newLOIE = learningObjectInstanceEnrollment?.relationships?.loResourceGrades?.data.map((loid) => {
            const progressPercent = courseDetailsIncluded?.find((element) => element.id === loid?.id)?.attributes?.progressPercent;
            // Use template literals to include both the modified loid and hasPassed value.
            return {
                id: loid?.id.split("_").slice(0, 4).join("_"),
                progressPercent: progressPercent
            };
        });
        let module = newLOIE?.find((item) => item?.progressPercent < 100);
        if (!module && newLOIE?.length > 0) {
            module = newLOIE[0]
        }
        if (courseDetails?.attributes?.loType === "jobAid") {
            if (!learningObjectInstanceEnrollment) {
                return (
                    <button
                        className="btn primary-btn primary-blue enrollbtn"
                        type="button"
                        onClick={() => handleButtonClickWithEnroll(courseDetails.id, loID)}
                    >
                        {t("course-detail.enroll_jobAid")}
                    </button>
                );
            } else if (learningObjectInstanceEnrollmentDetails?.attributes?.state === "COMPLETED" || learningObjectInstanceEnrollmentDetails?.attributes?.hasPassed) {
                return (
                    <button
                        className="btn primary-btn primary-blue enrollbtn"
                        type="button"
                        onClick={() => handleButtonJobAidClick()}
                    >
                        {t("course-detail.revisit_jobaid")}
                    </button>
                );
            }
        }
        else if (
            learningObjectInstanceEnrollment &&
            // learningObjectInstanceEnrollment.attributes.progressPercent == 0
            // && 
            // hasspassed added for nested lp start buttn change
            (learningObjectInstanceEnrollmentDetails?.attributes?.state === "ENROLLED" || learningObjectInstanceEnrollmentDetails?.attributes?.state === "Published") && !learningObjectInstanceEnrollmentDetails?.attributes?.hasPassed
        ) {
            return (
                <>
                    <button
                        className="btn primary-btn primary-blue enrollbtn"
                        type="button"
                        onClick={() => playCourse(id, "")}>
                        {t("course-detail.start")}
                    </button>
                    {isManager && courseDetails?.attributes?.loType !== "jobAid" && (
                        <button
                            className="btn primary-btn learner enrollbtn mt-3"
                            type="button"
                            onClick={() => handleAssignCourseToLearner(courseDetails.id, loID, courseDetails?.attributes?.localizedMetadata[0].name)}>
                            {t("course-detail.enroll_learner")}
                        </button>
                    )}
                </>
            );
        } else if (
            (learningObjectInstanceEnrollment &&
                learningObjectInstanceEnrollment?.attributes?.progressPercent >= 0 &&
                learningObjectInstanceEnrollment?.attributes?.progressPercent < 100) ||
            learningObjectInstanceEnrollmentDetails?.attributes?.state === "STARTED"
        ) {
            return (
                <>
                    <button
                        className="btn primary-btn primary-blue enrollbtn"
                        type="button"
                        onClick={() => playCourse(id, "")}>
                        {t("course-detail.continue")}
                    </button>
                    {isManager && courseDetails?.attributes?.loType !== "jobAid" && (
                        <button
                            className="btn primary-btn learner enrollbtn mt-3"
                            type="button"
                            onClick={() => handleAssignCourseToLearner(courseDetails.id, loID, courseDetails?.attributes?.localizedMetadata[0].name)}>
                            {t("course-detail.enroll_learner")}
                        </button>
                    )}
                </>
            );
        } else if (
            learningObjectInstanceEnrollment &&
            // learningObjectInstanceEnrollment.attributes.progressPercent === 100
            // hasspassed added for nested lp 
            (learningObjectInstanceEnrollmentDetails?.attributes?.state === "COMPLETED" || learningObjectInstanceEnrollmentDetails?.attributes?.hasPassed)

        ) {
            return (
                <>
                    <button
                        className="btn primary-btn primary-blue enrollbtn"
                        type="button"
                        onClick={() => playCourse(id, "")}>
                        {t("course-detail.revisit")}
                    </button>
                    {isManager && courseDetails?.attributes?.loType !== "jobAid" && (
                        <button
                            className="btn primary-btn learner enrollbtn mt-3"
                            type="button"
                            onClick={() => handleAssignCourseToLearner(courseDetails.id, loID, courseDetails?.attributes?.localizedMetadata[0].name)}>
                            {t("course-detail.enroll_learner")}
                        </button>
                    )}
                </>
            );
        } else {
            return (
                <>
                    <button
                        disabled={disabaledEnrollBtn && courseDetails?.attributes?.loType !== "jobAid"}
                        //className="btn primary-btn primary-blue enrollbtn"
                        className={`btn primary-btn enrollbtn ${!disabaledEnrollBtn || courseDetails?.attributes?.loType === "jobAid" ? "primary-blue" : ""}`}
                        type="button"
                        onClick={() =>
                            enrollToCourse(courseDetails.id, loID)
                            // enrollToCourse(courseDetails.id, courseDetails.relationships.instances.data[0].id)
                        }>
                        {t("course-detail.enroll")}
                    </button>
                    {isManager && courseDetails?.attributes?.loType !== "jobAid" && (
                        <button
                            disabled={disabaledEnrollBtn && courseDetails?.attributes?.loType !== "jobAid"}
                            //className="btn primary-btn primary-blue enrollbtn"
                            className={`btn primary-btn enrollbtn mt-3 ${!disabaledEnrollBtn || courseDetails?.attributes?.loType === "jobAid" ? "learner" : ""}`}
                            type="button"
                            onClick={() =>
                                handleAssignCourseToLearner(courseDetails.id, loID, courseDetails?.attributes?.localizedMetadata[0].name)
                                // enrollToCourse(courseDetails.id, courseDetails.relationships.instances.data[0].id)
                            }>
                            {t("course-detail.enroll_learner")}
                        </button>
                    )}
                </>
            );
        }
    };
    const startUiUnenroll = () => {
        let learningObjectInstanceEnrollment = courseDetailsIncluded?.find(
            (e) => e.type === "learningObjectInstanceEnrollment" && e.id?.toLowerCase().startsWith(courseDetails?.id?.toLowerCase())
        );

        if (learningObjectInstanceEnrollment && courseDetails?.attributes?.unenrollmentAllowed) {
            return (
                <>
                    <button
                        disabled={learningObjectInstanceEnrollment && learningObjectInstanceEnrollment.attributes.progressPercent === 100}
                        className={`btn primary-btn learner enrollbtn mt-3 ${!disabaledEnrollBtn || courseDetails?.attributes?.loType === "jobAid" ? "primary-blue" : ""}`}
                        type="button"
                        onClick={() =>
                            unenrollToCourse(learningObjectInstanceEnrollment.id)
                        }>
                        {t("course-detail.unenroll")}
                    </button>
                </>
            );
        }
    };


    let learningObjectInstanceEnrollmentdata = courseDetailsIncluded?.find(
        (e) =>
            e.type === "learningObjectInstanceEnrollment" &&
            e.id?.toLowerCase().startsWith(courseDetails?.id?.toLowerCase())

    );
    const skillDetailsUI = () => {
        let tempSkillDetails = courseDetails?.relationships?.skills?.data?.map((element) => {
            let learningObjectSkill = courseDetailsIncluded.find((learningObjectSkillElement) => {
                return learningObjectSkillElement.id === element.id;
            });
            let skill = courseDetailsIncluded.find((skillElement) => {
                return (
                    skillElement.id === learningObjectSkill.relationships.skillLevel.data.id.split("_")[0]
                );
            });
            return {
                credits: learningObjectSkill.attributes.credits,
                name: skill.attributes.name
            };
        });
        setSkillDetails(tempSkillDetails);
    };
    const handleRatingEmpty = () => { }
    const handleRating = async (i) => {
        setRatting(i)
    };
    const handleSubmitRating = async () => {
        if (learningObjectInstanceEnrollmentDetails) {
            if (ratting > 0) {
                let response = await apis.UpdateRrating(learningObjectInstanceEnrollmentDetails?.id, ratting);
                if (response.status == 200) {
                    setMessageforPopup("Thank you! Rating submitted successfully.");
                } else {
                    setMessageForFailure("Unable to submit the Rating. Please try again later.");
                }
                setRate(ratting)
                setShowModalforUrl(true);
            }
            else {
                setMessageForFailure("Please select rating.");
                setShowModalforUrl(true)
            }
        } else {
            NotificationManager.warning(t("Course-is-not-enrolled"));
        }
    }

    const handleSelectedSkils = (e, data) => {
        setSelectedSkillIds(prevState => {
            const temp = [...prevState];
            const skillId = data?.id;
            const SkillName = data?.name;
            const index = temp?.findIndex(obj => obj?.skillId === skillId);
            if (index !== -1) {
                temp.splice(index, 1);
            } else {
                const obj = {
                    "skillId": skillId,
                    "SkillName": SkillName
                };
                temp.push(obj);
            }
            return temp;
        });
    }

    // submit skill for approval
    const handleSubmit = async () => {
        if (selectedSkillIds.length > 0) {
            setShowLoader(true);
            try {
                const payload = selectedSkillIds.map((skill) => ({
                    "roleId": skill?.RoleId || 0,
                    "groupId": skill?.SkillGroupId || 0,
                    "skillId": skill?.skillId,
                    "RoleName": skill?.RoleName || "",
                    "SkillName": skill?.SkillName,
                    "SkillGroupName": skill?.GroupName || ""
                }));
                let res = await apis.getCheckUserRoleSkills(payload)

                if (res?.status == false) {
                    setShowLoader(false);
                    setDisplayMessageBox(true);
                } else {
                    setShowLoader(false);
                    setDisplayMessageBox(false)
                    navigate("/myskilljourney", { state: { courseDetails: true, pathname: location } })
                }
                let newObj = {
                    ...res,
                    "payload": payload,
                    "roleWiseSkillsData": roleWiseSkillsData
                };
                setShowLoader(false);
                dispatch({ type: "CheckUserRoleSkillsItem", payload: newObj });

            } catch (error) {
                console.log(error)
            }
        } else {
            setMessageForFailure("Please select at least one Skill to continue.");
            setWarningPopup("");
            setMessageforPopup("")
            setShowModalforUrl(true);
        }
    };

    const handleCloseConfirmForAssign = () => {
        setDisplayMessageBoxForConfirmation(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false)
    };

    const handleCloseConfirmForAssignCourse = () => {
        setDisplayMessageBoxForConfirmationCourse(false);
    };

    const handleCloseDisplayMessage = () => {
        setDisplayMessageBox(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false)
    };

    const handleOkDisplayMessage = () => {
        setDisplayMessageBox(false);
        navigate("/myskillJourney", { state: { courseDetails: true, pathname: location } })
    };

    const getPayloadForValidation = (roleskillGroupData, userIds) => {
        const uniqueRoleskillGroupData = [];
        roleskillGroupData.map(element => {
            const isElementExist = uniqueRoleskillGroupData.find(ele => {
                return ele.skillId === element.skillId
            })
            if (!isElementExist) {
                uniqueRoleskillGroupData.push(element);
            }
        })
        return {
            roleSkillGroupData: uniqueRoleskillGroupData,
            users: [...new Set(userIds)]
        }
    }

    //  for assign skill to  myself 
    const handleChange = async () => {
        setAssignToLearner(false)
        if (selectedSkillIds.length > 0) {
            const payload = selectedSkillIds.map((skill) => ({
                "roleId": skill?.RoleId || 0,
                "groupId": skill?.SkillGroupId || 0,
                "skillId": skill?.skillId,
                "RoleName": skill?.RoleName || "",
                "SkillName": skill?.SkillName,
                "SkillGroupName": skill?.GroupName || ""
            }));
            set_UserId("")
            let res = await apis.validateUserRoleSkill(getPayloadForValidation(payload, [JSON.parse(localStorage.getItem("CPToken")).user_id]))
            setCheckUserRes(res)
            if (!(res?.assignUserSkills?.response)) {
                setShowModal(true);
                setMessageForFailure("");
                setWarningPopup("");
                setMessageforPopup("")
                setShowModal(false);
                setShowModalforUrl(false);
                setDisplayMessageBoxForConfirmation(false);
                setDisplayMessageBoxForalreadyAssignRoleSkill(true)
            } else {
                setShowModal(false);
                setMessageForFailure("");
                setWarningPopup("");
                setMessageforPopup("")
                setShowModal(false);
                setShowModalforUrl(false);
                setDisplayMessageBoxForConfirmation(true);
                setDisplayMessageBoxForalreadyAssignRoleSkill(false)
            }
        } else {
            setDisplayMessageBoxForConfirmation(false);
            setMessageForFailure("Please select at least one Skill to continue.");
            setWarningPopup("");
            setMessageforPopup("")
            setShowModalforUrl(true);
            setDisplayMessageBoxForalreadyAssignRoleSkill(false)
        }
    };


    const handleAssignSkillToMySelf = async () => {
        try {
            let updatedUserId = userProfile?.id
            if (_userId && _userId !== "") {
                updatedUserId = _userId.join(",");
            }
            const payload = {
                users: updatedUserId,
                roleSkillGrpIds: "",
                skills: selectedSkillIds?.map(item => item.skillId).join(",")
            };
            if (selectedSkillIds?.length === 0) {
                // toast.error("Please select at least one skill and try again");
                setMessageForFailure("Please select at least one Skill to continue.");
                setWarningPopup("");
                setMessageforPopup("")
                setShowModalforUrl(true);
            } else if (selectedSkillIds?.length > 0) {
                setShowLoader(true);
                const response = await apis.assignSkillToUser(payload);
                if (!response) {
                    // toast.error("An error occurred while assigning skill, please try again");
                    setMessageforPopup("An error occurred while assigning Skill(s), please try again.");
                    setWarningPopup("");
                    setShowLoader(false)
                    setShowModalforUrl(true);
                } else {
                    // toast.success(response?.data?.message);
                    setShowLoader(false);
                    setMessageForFailure("");
                    setMessageforPopup(response?.data?.message);
                    setWarningPopup("");
                    setShowModalforUrl(true);
                    setSelectedSkillIds([]);
                }
            }

        } catch (error) {
            console.log(error)
        }

    }

    const handleAssignCourseToLearner = async (id, loId, name) => {
        const payload = {
            courseName: name,
            courseId: id,
            courseInstanceId: loId
        };
        const currentURL = window.location.href;
            const paths = currentURL.split("/");
            const loInstanceId = paths[5];
            const loInstanceIdArray = courseDetails?.relationships?.instances?.data?.map((instanceId) =>
                instanceId.id);
                if (!loInstanceIdArray.includes(loInstanceId)) {
                    const courseDetailsIncludedObj = courseDetailsIncluded.find((e) => e.id === courseDetails?.relationships?.instances?.data[0]?.id && e.type === "learningObjectInstance");
                        if(courseDetailsIncludedObj.attributes.isDefault && courseDetailsIncludedObj.attributes.state === 'Active'){
                            payload.courseInstanceId =  courseDetailsIncludedObj.id
                        } else {
                        setWarningPopup("Default Instance is not Found.");
                        }
                
                }
        setSkillPayload(payload);
        setShowLoader(true);
        setSelectedOptionForLerner(3);
        setShowLoader(false);
        setShowModal(true);
        setDisplayMessageBoxForConfirmationCourse(false);
        // setMessageforPopup("Thank you! Rating submitted successfully.");
    }
    // payload for validation for assign skill to learner 

    const handleAssignSkillToLerner = async (selectedSkills) => {
        setAssignToLearner(true)
        if (selectedSkills.length > 0) {
            const payload = selectedSkillIds.map((skill) => ({
                "roleId": skill?.RoleId || 0,
                "groupId": skill?.SkillGroupId || 0,
                "skillId": skill?.skillId,
                "RoleName": skill?.RoleName || "",
                "SkillName": skill?.SkillName,
                "SkillGroupName": skill?.GroupName || ""
            }));
            setSkillPayload(payload)
            setShowLoader(true);
            setSelectedOptionForLerner(2);
            setShowLoader(false);
            setShowModal(true);
            setDisplayMessageBoxForConfirmation(false);
            // setMessageforPopup("Thank you! Rating submitted successfully.");
        } else {
            setMessageForFailure("Please select at least one Skill to continue.");
            setMessageforPopup("");
            setWarningPopup("");
            setShowModalforUrl(true);
        }
    }

    const setCheckedData = (e) => {
        if (selectedSkillIds?.length !== 0) {
            return selectedSkillIds?.some((item) => item?.skillId === e?.id);
        } else {
            return false;
        }
    }
    // TODO - navigate to instance listing page
    const handleViewAllInstances = () => {
        navigate(-1)
    }

    const handleConfirmForAssign = async () => {
        setDisplayMessageBoxForConfirmation(false);
        setDisplayMessageBoxForalreadyAssignRoleSkill(false)
        if (selectedSkillIds.length > 0) {
            setShowLoader(true);
            await handleAssignSkillToMySelf().then(setSelectedSkillIds([]));
            setShowLoader(false);
        } else {
            handleAssignSkillToMySelf();
        }
    };

    const handleAssignCourseforLearner = async () => {
        let updatedUserId = userProfile?.id
        if (_userId && _userId !== "") {
            updatedUserId = _userId.join(",");
        }
        const payload = {
            users: updatedUserId,
            courseName: skillPayload.courseName,
            courseId: skillPayload.courseId,
            courseInstanceId: skillPayload.courseInstanceId
        };
        setShowLoader(true);
        const response = await apis.assignCourseToLearners(payload);

        //List of failed users
        const enrolled_users = response?.data?.EnrolledUsers.map(user => user.name).join(', ');
        const retired_users = response?.data?.RetiredFailure.map(user => user.name).join(', ');
        const alm_enrollment_users = response?.data?.ALMEnrollmentFailed.map(user => user.name).join(', ');
        const lp_enrolled_users = response?.data?.LpEnrolled.map(user => user.name).join(', ');

        if (response?.data?.message === "none_enrolled") {

            const text = (
                <>
                    <>Unable to enroll course {skillPayload.courseName} to Learner(s).</>
                    {retired_users && (
                        <p style={{ textAlign: 'left', padding: '0 1rem' }}>
                            <hr/>
                            <>Enrollment failed for: {retired_users}</>
                            <br/>
                            <>
                                Error: Enrollment Closed or not Available, please contact{" "}
                                <a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a>
                            </>
                        </p>
                    )}
                    {alm_enrollment_users && (
                        <p style={{ textAlign: 'left', padding: '0 1rem' }}>
                            <hr/>
                            <>Enrollment failed for: {alm_enrollment_users}</>
                            <br/>
                            <>
                                Error: Unable to process Enrolment in ALM. Please try after sometime.{" "}
                                If you need more help please contact{" "}
                                <a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a>
                            </>
                        </p>
                    )}
                    {lp_enrolled_users && (
                        <p style={{ textAlign: 'left', padding: '0 1rem' }}>
                            <hr/>
                            <>Enrollment failed for: {lp_enrolled_users}</>
                            <br/>
                            <>Error: Possibly Learner(s) are already enrolled to a Learning Plan Instance.</>
                        </p>
                    )}
                </>
            );

            setMessageforPopup("");
            setWarningPopup("");
            setMessageForFailure(text)
            setShowLoader(false)
            setShowModalforUrl(true);
        }
        else if (response?.data?.message === "partially_enrolled") {
            const text = (
                <>
                    <>{skillPayload.courseName} have been successfully enrolled to some of the selected Learner(s).</>
                    {enrolled_users && (
                        <p style={{ textAlign: 'left', padding: '0 1rem' }}>
                            <hr/>
                            <>Users Enrolled Successfully: {enrolled_users}</>
                        </p>
                    )}
                    {retired_users && (
                        <p style={{ textAlign: 'left', padding: '0 1rem' }}>
                            <hr/>
                            <>Enrollment failed for: {retired_users}</>
                            <br/>
                            <>
                                Error: Enrollment Closed or not Available, please contact{" "}
                                <a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a>
                            </>
                        </p>
                    )}
                    {alm_enrollment_users && (
                        <p style={{ textAlign: 'left', padding: '0 1rem' }}>
                            <hr/>
                            <>Enrollment failed for: {alm_enrollment_users}</>
                            <br/>
                            <>
                                Error: Unable to process Enrolment in ALM. Please try after sometime.{" "}
                                If you need more help please contact{" "}
                                <a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a>
                            </>
                        </p>
                    )}
                    {lp_enrolled_users && (
                        <p style={{ textAlign: 'left', padding: '0 1rem' }}>
                            <hr/>
                            <>Enrollment failed for: {lp_enrolled_users}</>
                            <br/>
                            <>Error: Possibly Learner(s) are already enrolled to a Learning Plan Instance.</>
                        </p>
                    )}
                </>
            );

            setMessageforPopup(text);
            setWarningPopup("");
            setMessageForFailure("");
            setShowLoader(false)
            setShowModalforUrl(true);
        } else if (response?.data?.message === "all_enrolled") {
            const text = skillPayload.courseName + " have been successfully enrolled to the selected Learner(s).";

            setMessageforPopup(text);
            setWarningPopup("");
            setMessageForFailure("");
            setShowLoader(false)
            setShowModalforUrl(true);
        } else {
            const link = (
                <>
                    Unable to enroll course {skillPayload.courseName} to Learner(s), please contact{" "}
                    <a href="mailto:dx-sio-enablement@adobe.com">dx-sio-enablement@adobe.com</a>.
                </>
            );

            setMessageforPopup("");
            setWarningPopup("");
            setMessageForFailure(link)
            setShowLoader(false)
            setShowModalforUrl(true);
        }
    }

    const handleConfirmForAssignCourse = async () => {
        setDisplayMessageBoxForConfirmationCourse(false);
        setShowLoader(true);
        await handleAssignCourseforLearner();
        setShowLoader(false)
    };

    useEffect(() => {
        setIsManger(userProfile?.attributes?.roles?.indexOf("Manager") > -1 ? true : false);
    }, []);

    useEffect(() => {
        skillDetailsUI();
        setIsManger(userProfile?.attributes?.roles?.indexOf("Manager") > -1 ? true : false);
    }, [courseDetails]);

    useEffect(() => {
        //TODO - Hide Old Code
        // const findMatchingSkills = () => {
        //   const matchedSkills = skillDetails?.filter((aSkill) =>
        //     skillsListData?.some((bSkill) => bSkill?.SkillName === aSkill?.name)
        //   );
        //   if (matchedSkills) {

        //     const skillsWithAdditionalData = matchedSkills.reduce((accumulator, matchedSkill) => {

        //       const matchingSkillData = skillsListData.find(
        //         (bSkill) => bSkill?.SkillName === matchedSkill?.name
        //       );

        //       if (!accumulator[matchedSkill.name]) {
        //         accumulator[matchedSkill.name] = {
        //           id: matchingSkillData?.id,
        //           name: matchedSkill.name,
        //           credits: matchedSkill.credits,
        //         };
        //       }

        //       return accumulator;
        //     }, {});

        //     const uniqueSkills = Object.values(skillsWithAdditionalData);
        //     setMatchingSkills(uniqueSkills);
        //   }
        // };    

        // findMatchingSkills()
        setMatchingSkills(associatedSkills(courseDetails, courseDetailsIncluded))
    }, [skillDetails, skillsListData, roleWiseSkillsData]);

    useEffect(() => {
        apiCall();
    }, [course_id]);
    /////////////////////////// TODO - FOR ADDITION OF SKILL LEVEL
    const associatedSkills = (item, includedArr) => {
        let tempSkillDetails = item?.relationships?.skills?.data?.map((element) => {
            let learningObjectSkill = includedArr.find((learningObjectSkillElement) => {
                return element?.id.split("_")[0] === item?.id && learningObjectSkillElement.id === element.id;
            });
            let skillLevel = includedArr.find((skillElement) => {
                return (
                    skillElement.id === learningObjectSkill?.relationships?.skillLevel?.data.id
                );
            });
            let skill = includedArr.find((skillElement) => {
                return (
                    skillElement.id === skillLevel?.relationships?.skill?.data.id
                );
            });
            let convertedLevel;
            switch (skillLevel?.attributes?.level) {
                case "1":
                    convertedLevel = 100;
                    break;
                case "2":
                    convertedLevel = 200;
                    break;
                case "3":
                    convertedLevel = 300;
                    break;
            }
            return {
                name: skill?.attributes?.name,
                level: convertedLevel,
                id: skill?.id
            };
        });

        const commonSkills = [];
        skillsListData?.forEach((itemA) => {
            const matchingSkills = tempSkillDetails?.find((itemB) => itemB?.name === itemA?.SkillName);
            if (matchingSkills) {
                commonSkills.push({
                    name: matchingSkills?.name,
                    level: matchingSkills?.level,
                    id: itemA?.id
                });
            }
        });
        return commonSkills;
    };
    ////////////////////////
    useEffect(() => {
        if (instance_id) {
            setIsLPInstanceList(true);
        } else {
            setIsLPInstanceList(false);
        }
    }, [instance_id]);

    useEffect(() => {
        if (courseDetails?.attributes?.loType === "learningProgram")
            if (timeOut && timeOut.length > 0)
                setIsLPInstanceList(true);
    }, [timeOut]);

    useEffect(() => {
        if (courseDetails?.attributes?.loType !== "course" && courseDetails?.attributes?.loType !== "learningProgram") {
            if (jobAids.length) {
                setIsLPInstanceList(true);
            }
        }
    }, [jobAids])

    const onClickHereHandler = () => {
        const currentUrl = window.location.href;
        const email = "dx-sio-enablement@adobe.com";
        let subject = "Course/LP is retired and cannot be enrolled";
        // for spacing chnages in email nad content for email
        // let body = `Hello DX SIO Enablement Team,\n\nThe below Course/LP is retired and needs immediate attention -\n\n\tCourse/LP Name: ${courseDetails?.attributes?.localizedMetadata[0].name}\tCourse/LP URL: ${currentUrl}\n\nThank You.\n\n`;
        // do for spacing issue in email for Course/LP Name for 
        let body = `Hello DX SIO Enablement Team,\n\nThe below Course/LP is retired and needs immediate attention -\n\n      Course/LP Name: ${courseDetails?.attributes?.localizedMetadata[0].name}\n      Course/LP URL: ${currentUrl}\n\nThank You.\n\n`;
        const encodedSubject = encodeURIComponent(subject);
        const encodedBody = encodeURIComponent(body);
        window.location.href = `mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`;
    }

    // --------------------- divide data according to pre work and core content------------
    const groupedData = (data) => {
        const ArrData = data?.reduce((result, item) => {
            const key = item?.loResourceType;
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(item);
            return result;
        }, {});

        return ArrData
            ? Object?.keys(ArrData).map((sectionName) => ({
                sectionName: sectionName === "Content" ? (
                    <> Core Content {
                        courseInstances[0]?.loResourceCompletionCount && (
                            <span>{(courseInstances[0]?.loResourceCompletionCount !== courseInstances[0]?.learningObjectInstanceModuleLength) ? <span className="section-btn">Required {courseInstances[0]?.loResourceCompletionCount} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span> : null}</span>
                        )
                    }
                        {
                            courseInstances[0]?.loResourceCompletionCount === undefined && (
                                <span>{<span className="section-btn">Required {coreContentItemModule} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span>}</span>
                            )
                        }
                        {courseInstances[0]?.loResourceCompletionCount && (
                            <span>{(courseInstances[0]?.loResourceCompletionCount === courseInstances[0]?.learningObjectInstanceModuleLength) ? <span className="section-btn">Required {courseInstances[0]?.loResourceCompletionCount} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span> : null}</span>
                        )
                        }</>
                ) : sectionName,
                courses: ArrData[sectionName],
            }))
            : []
    };
    const filterDataByPreWorkAndContent = groupedData(courseInstancesWithTestOut);
    const filteredModules = courseInstances?.find((coreContentItem) => coreContentItem?.instanceId === instance_id)?.modules?.filter(item => item?.loResourceType !== "Test Out") || [];
    const filterDataByPreWorkAndContent2 = groupedData(filteredModules);
    const showErrorScreen = (show) => {
        setErrorScreen(show);
      };
    // --------------------END--------------------

    return (
        <>
            <Breadcrumbs crumbs={crumbs} />
            {
                courseDetails && <Container fluid className="pl-30 py-4 ">
                    <div className="headerImage course_details d-flex justify-content-between align-items-start flex-wrap">
                        {
                            errorMsg ? "" :
                                <div className="p-4 custom-width">
                                    <h1>{courseDetails?.attributes?.localizedMetadata[0].name}</h1>
                                    <h4>{(courseDetails?.attributes?.loType === "jobAid") ? "Job Aid" : courseDetails?.attributes?.loFormat}</h4>
                                    <h4>{courseDetails?.attributes?.localizedMetadata[0].description}</h4>
                                    {
                                        courseDetails?.attributes?.loType === "jobAid" || courseDetails?.attributes?.loType === "certification" ? <></> :

                                            <div className="px-4 course-rating mt-2 position-relative">

                                                <div className="fill-star">
                                                    <RatingFeedBack
                                                        handleRating={handleRatingEmpty}
                                                        number={courseDetails?.attributes?.rating?.averageRating || 0}
                                                    />
                                                </div>
                                                <div className="blankstar">
                                                    <RatingFeedBack
                                                        handleRating={handleRatingEmpty}
                                                        number={0}
                                                    />
                                                    <span className="ratingStar">{courseDetails?.attributes?.rating?.ratingsCount}</span>
                                                    <span className="ratingStyles">Rating(s)</span>

                                                </div>
                                            </div>
                                    }
                                    {(learningObjectInstanceEnrollmentDetails?.attributes?.state === "ENROLLED" ||
                                        learningObjectInstanceEnrollmentDetails?.attributes?.state === "STARTED" ||
                                        learningObjectInstanceEnrollmentDetails?.attributes?.state === "COMPLETED"
                                    ) && (
                                            <div className="course-details-progress px-4 d-flex align-items-center">
                                                <div className="col-8 col-sm-6 col-lg-8 mt-2">
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{ width: `${progressPercent}%` }}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="px-3 percentageValue">{progressPercent}%</div>
                                            </div>
                                        )}
                                </div>
                        }
                        {
                            errorMsg ? "" :
                                <>
                                    <div className="sharebtn-box" ref={divRef}>
                                        <button className="btn primary-btn me-5 mt-4 sharebtn" onClick={toggleSharebtnDropdown}>
                                            <img src={share_icon} alt="share" /> <span className="ms-2">{t("Share")}</span>
                                        </button>
                                        {isDivVisible &&
                                            <div className="sharebtn-dropdown">
                                                <div className="share-item" onClick={() => toggleSharebtnDropdown("url")} onKeyDown={handleItemKeyPress} role="button" tabIndex="0">
                                                    <div className="icon "><img src={copy_url_icon} alt="Copy URL" /></div>
                                                    <div className="item-name">Copy URL</div>
                                                </div>
                                                <div className="share-item" onClick={() => toggleSharebtnDropdown("email")} onKeyDown={handleItemKeyPress} role="button" tabIndex="0">
                                                    <div className="icon "><img src={share_email_icon} alt="E-mail" /></div>
                                                    <div className="item-name">E-mail</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                        }

                    </div>
                </Container>
            }

            {showLoader ? (
                <div className="show-loader">
                    <Loader />
                </div>
            ) : (
                <Container fluid className="pl-30 course_details">
                    <Row>
                        {(errorMsg === true) && (
                            <h1 className="my-5 m-auto col-lg-12 noData ">No course found.</h1>
                        )
                        }
                        <div className="">
                            <Container fluid>
                                <Row>
                                    {/* {(courseDetails?.attributes?.localizedMetadata[0]?.richTextOverview && errorMsg)&&(<h1 className="my-5 m-auto col-lg-12 noData ">No course found.</h1>)} */}
                                    <Col sm={12} md={7} lg={8} xl={8} xxl={9} className="px-xxl-0">

                                        <div className="sub-menu-list">
                                            {
                                                errorMsg ? "" : <div className="card-footer sub-menu">
                                                    <img src={time_icon} alt="star1" title="Duration" className="ms-2"></img>
                                                    <span className="ms-2 sub-page">
                                                        {secondsToHms(courseDetails?.attributes.duration) || "No Duration"}
                                                    </span>
                                                    {courseDetails?.attributes?.loType !== "jobAid" && <><span className="right-bar pe-3 me-3"></span><img src={user1} className="px-2" alt="Enrollment Count" title="Enrollment Count"></img><span className="ms-2 sub-page">{instance_id === undefined ? enrollmentCounts : enrollmentInstanceCounts}</span></>}
                                                </div>
                                            }
                                            <div className=" fontSize13 description-block">
                                                {(errorMsg === false) &&
                                                    (courseDetails?.attributes?.localizedMetadata[0]?.richTextOverview ? (
                                                        <DynamicHTMLContent
                                                            htmlContent={courseDetails?.attributes?.localizedMetadata[0]?.richTextOverview}
                                                        />
                                                    ) :
                                                        <div className="ms-2 mb-1 noData">No description available.</div>

                                                    )}

                                            </div>
                                            {(!courseDetails?.relationships?.enrollment && disabaledEnrollBtn && courseDetails?.attributes?.loType !== "jobAid") && (<div className="ms-2 mb-1 noData">The selected learning object is not available for enrollment. Please <button className="btn btn-link btn-view fontSize15Text p-0 fw-bold" onClick={onClickHereHandler}>click here</button> to connect with the DX SIO Enablement Team.</div>)}
                                            {
                                                courseDetails?.attributes?.loType === "course" ?
                                                    courseInstances?.length == 1 ?
                                                        // -----Prerequisite
                                                        prerequisite_id ?
                                                            <PrerequisiteCourseDetails
                                                                coreContentItem={prerequisiteCourse?.find(item => item.instanceId == prerequisite_id)}
                                                                courseDetails={courseDetails}
                                                                startUi={startUi}
                                                                isVisibleBtn={true}
                                                            />
                                                            :
                                                            (<>
                                                                {
                                                                    prerequisiteCourse.length > 0 ?
                                                                        <div className="prerequisite">
                                                                            <>
                                                                                <h3 className="fw-bold mb-4">Prerequisite
                                                                                    {
                                                                                        (isPrerequisiteEnforcedCourse ? <span className="section-btn">Required {prerequisiteLengthForCourse} out of {prerequisiteLengthForCourse}</span> : <span className="optional-btn">Optional</span>)
                                                                                    }
                                                                                </h3>
                                                                            </>
                                                                            {
                                                                                prerequisiteCourse?.map((coreContentItem, i) => {
                                                                                    return <PrerequisiteCourse
                                                                                        key={i}
                                                                                        coreContentItem={coreContentItem}
                                                                                        courseDetails={courseDetails}
                                                                                        startUi={startUi}
                                                                                    />
                                                                                })
                                                                            }
                                                                        </div>
                                                                        : <></>
                                                                }
                                                                <div className="horizontal-tab-block blue-border my-5">
                                                                    <Tabs
                                                                        id="controlled-tab-example"
                                                                        activeKey={key}
                                                                        onSelect={(k) => setKey(k)}
                                                                        className="mb-3 ps-0 pt-2">
                                                                        <Tab Tab eventKey="Module" title="Module(s)">
                                                                            {/* {
                                    courseInstances[0]?.loResourceCompletionCount && (<span>{(courseInstances[0]?.loResourceCompletionCount !== courseInstances[0]?.learningObjectInstanceModuleLength) ? <span className="section-btn">Required {courseInstances[0]?.loResourceCompletionCount} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span> : null}</span>)
                                  }
                                  {
                                    courseInstances[0]?.loResourceCompletionCount=== undefined && (<span>{ <span className="section-btn">Required {coreContentItemModule} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span>}</span>)
                                  }
                                mt-4 mb-h3
                                  {
                                    courseInstances[0]?.loResourceCompletionCount && (<span>{(courseInstances[0]?.loResourceCompletionCount === courseInstances[0]?.learningObjectInstanceModuleLength) ? <span className="section-btn">Required {courseInstances[0]?.loResourceCompletionCount} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span> : null}</span>)
                                  } */}
                                                                            {/* coreContentItemModule */}
                                                                            {filterDataByPreWorkAndContent?.map((section) => {
                                                                                return (
                                                                                    <div key={section?.sectionName}>
                                                                                        <h3 className="mt-4 mb-0 fw-bold">{section?.sectionName}</h3>
                                                                                        {section?.courses.map((course, index) => {

                                                                                            let isPreviousCourseCompleted = courseDetails?.attributes?.isSubLoOrderEnforced === true ?
                                                                                                (index === 0 || section?.courses[index - 1]?.progressPercent === 100 || section?.courses[index - 1]?.hasPassed === true) : true

                                                                                            if (course.loResourceType === "Pre Work") {
                                                                                                isPreviousCourseCompleted = true;
                                                                                            }
                                                                                            return (
                                                                                                <SingleInstanceDetails
                                                                                                    key={course.id}
                                                                                                    handleLoader={handleLoader}
                                                                                                    apiCall={apiCall}
                                                                                                    coreContentItem={course}
                                                                                                    courseDetails={courseDetails}
                                                                                                    startUi={startUi}
                                                                                                    startUiUnenroll={startUiUnenroll}
                                                                                                    isVisibleBtn={isPreviousCourseCompleted}
                                                                                                    prerequisiteCourse={prerequisiteCourse}
                                                                                                    crumbs={crumbs}
                                                                                                    showErrorScreen={showErrorScreen}
                                                                                                // isRevisit={isRevisit}
                                                                                                // hasPassed={hasPassed}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </Tab>
                                                                        {
                                                                            timeOut.length > 0 && <Tab eventKey="Testout" title="Testout">
                                                                                {
                                                                                    // ---------------Single Instance OR course Details -----------
                                                                                    courseInstances[0]?.modules?.map((coreContentItem, i) => {
                                                                                        let isRevisit = courseInstances[0]?.modules[i]?.progressPercent
                                                                                        let hasPassed = courseInstances[0]?.modules[i]?.hasPassed
                                                                                        if (coreContentItem.loResourceType === "Test Out") {
                                                                                            return (
                                                                                                <SingleInstanceDetails
                                                                                                    handleLoader={handleLoader}
                                                                                                    apiCall={apiCall}
                                                                                                    coreContentItem={coreContentItem}
                                                                                                    key={i}
                                                                                                    courseDetails={courseDetails}
                                                                                                    startUi={startUi}
                                                                                                    startUiUnenroll={startUiUnenroll}
                                                                                                    isVisibleBtn={true}
                                                                                                    prerequisiteCourse={prerequisiteCourse}
                                                                                                    isRevisit={isRevisit}
                                                                                                    hasPassed={hasPassed}
                                                                                                    crumbs={crumbs}
                                                                                                    showErrorScreen={showErrorScreen}
                                                                                                />
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                            </Tab>
                                                                        }
                                                                    </Tabs>
                                                                </div>
                                                            </>)
                                                        :
                                                        (
                                                            instance_id ?
                                                                (<>
                                                                    {
                                                                        // -----Prerequisite
                                                                        prerequisiteCourse.length > 0 ?
                                                                            <div className="prerequisite">
                                                                                <>
                                                                                    <h3 className="fw-bold mb-4">Prerequisite
                                                                                        {
                                                                                            (isPrerequisiteEnforcedCourse ? <span className="section-btn">Required {prerequisiteLengthForCourse} out of {prerequisiteLengthForCourse}</span> : <span className="optional-btn">Optional</span>)
                                                                                        }
                                                                                    </h3>
                                                                                </>
                                                                                {
                                                                                    prerequisiteCourse?.map((coreContentItem, i) => {
                                                                                        return <PrerequisiteCourse
                                                                                            key={i}
                                                                                            coreContentItem={coreContentItem}
                                                                                            courseDetails={courseDetails}
                                                                                            startUi={startUi}
                                                                                        />
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            : <></>
                                                                    }
                                                                    {/* multi instance odered course */}
                                                                    <div className="horizontal-tab-block blue-border my-5">
                                                                        <Tabs
                                                                            id="controlled-tab-example"
                                                                            activeKey={key}
                                                                            onSelect={(k) => setKey(k)}
                                                                            className="mb-3 ps-0 pt-2">
                                                                            <Tab Tab eventKey="Module" title="Module(s)">
                                                                                {
                                                                                    filterDataByPreWorkAndContent2?.map((section) => {
                                                                                        return (
                                                                                            <div key={section?.sectionName}>
                                                                                                <h3 className="mt-4 mb-0 fw-bold">{section?.sectionName}</h3>
                                                                                                {section?.courses.map((course, key) => {
                                                                                                    let isPreviousCourseCompleted = courseDetails?.attributes?.isSubLoOrderEnforced === true ?
                                                                                                        (key === 0 || section?.courses[key - 1]?.progressPercent === 100 || section?.courses[key - 1]?.hasPassed === true) : true
                                                                                                    if (course.loResourceType === "Pre Work") {
                                                                                                        isPreviousCourseCompleted = true;
                                                                                                    }
                                                                                                    return (
                                                                                                        <SingleInstanceDetails
                                                                                                            key={course.id}
                                                                                                            handleLoader={handleLoader}
                                                                                                            apiCall={apiCall}
                                                                                                            coreContentItem={course}
                                                                                                            courseDetails={courseDetails}
                                                                                                            startUi={startUi}
                                                                                                            startUiUnenroll={startUiUnenroll}
                                                                                                            isVisibleBtn={isPreviousCourseCompleted}
                                                                                                            prerequisiteCourse={prerequisiteCourse}
                                                                                                            crumbs={crumbs}
                                                                                                            name='vishal33333'
                                                                                                            showErrorScreen={showErrorScreen}
                                                                                                        // isRevisit={isRevisit}
                                                                                                        // hasPassed={hasPassed}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                                )}
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Tab>
                                                                            {
                                                                                timeOut.length > 0 && <Tab eventKey="Testout" title="Testout">
                                                                                    {
                                                                                        courseInstances[0]?.modules?.map((coreContentItem, i) => {
                                                                                            if (coreContentItem.loResourceType === "Test Out") {
                                                                                                return (
                                                                                                    <SingleInstanceDetails
                                                                                                        handleLoader={handleLoader}
                                                                                                        apiCall={apiCall}
                                                                                                        coreContentItem={coreContentItem}
                                                                                                        key={i}
                                                                                                        courseDetails={courseDetails}
                                                                                                        startUi={startUi}
                                                                                                        startUiUnenroll={startUiUnenroll}
                                                                                                        isVisibleBtn={true}
                                                                                                        prerequisiteCourse={prerequisiteCourse}
                                                                                                        crumbs={crumbs}
                                                                                                        name="vishal444"
                                                                                                        showErrorScreen={showErrorScreen}
                                                                                                    />
                                                                                                );
                                                                                            }
                                                                                        })}
                                                                                </Tab>
                                                                            }
                                                                        </Tabs>
                                                                    </div>
                                                                </>)
                                                                :
                                                                // -----Prerequisite
                                                                prerequisite_id ?
                                                                    <PrerequisiteCourseDetails
                                                                        coreContentItem={prerequisiteCourse?.find(item => item.instanceId == prerequisite_id)}
                                                                        courseDetails={courseDetails}
                                                                        startUi={startUi}
                                                                        isVisibleBtn={false}
                                                                    />
                                                                    :
                                                                    // ----- Multi instance courses-----
                                                                    courseInstances.find(EI => EI.hasEnrolled) ?
                                                                        // -----------
                                                                        <div className="horizontal-tab-block blue-border my-5">
                                                                            <Tabs
                                                                                id="controlled-tab-example"
                                                                                activeKey={key}
                                                                                onSelect={(k) => setKey(k)}
                                                                                className="mb-3 ps-0 pt-2">
                                                                                <Tab Tab eventKey="Module" title="Module(s)">
                                                                                    {
                                                                                        courseInstances.find(EI => EI.hasEnrolled)?.modules?.filter(item => item.loResourceType !== "Test Out")?.map((coreContentItem, i) => {
                                                                                            //unorderd multi
                                                                                            let isRevisit = courseInstances[selectedInstance]?.modules?.filter(item => item.loResourceType !== "Test Out")[i]?.progressPercent
                                                                                            let hasPassed = courseInstances[selectedInstance]?.modules?.filter(item => item.loResourceType !== "Test Out")[i]?.hasPassed
                                                                                            let isPreviousCourseCompleted = courseDetails?.attributes?.isSubLoOrderEnforced === true ?
                                                                                                (i === 0 || courseInstances[selectedInstance]?.modules?.filter(item => item.loResourceType !== "Test Out")[i - 1]?.progressPercent === 100 || courseInstances[selectedInstance]?.modules?.filter(item => item.loResourceType !== "Test Out")[i - 1]?.hasPassed === true) : true
                                                                                            if (coreContentItem.loResourceType !== "Test Out") {
                                                                                                return <SingleInstanceDetails
                                                                                                    handleLoader={handleLoader}
                                                                                                    apiCall={apiCall}
                                                                                                    coreContentItem={coreContentItem}
                                                                                                    key={i}
                                                                                                    courseDetails={courseDetails}
                                                                                                    startUi={startUi}
                                                                                                    startUiUnenroll={startUiUnenroll}
                                                                                                    isVisibleBtn={isPreviousCourseCompleted}
                                                                                                    prerequisiteCourse={prerequisiteCourse}
                                                                                                    crumbs={crumbs}
                                                                                                    isRevisit={isRevisit}
                                                                                                    hasPassed={hasPassed}
                                                                                                    name='vishal555'
                                                                                                    showErrorScreen={showErrorScreen}
                                                                                                />
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </Tab>
                                                                                {
                                                                                    timeOut.length > 0 && <Tab eventKey="Testout" title="Testout">
                                                                                        {
                                                                                            courseInstances.find(EI => EI.hasEnrolled)?.modules?.map((coreContentItem, i) => {
                                                                                                if (coreContentItem.loResourceType !== "Test Out") {
                                                                                                    return <SingleInstanceDetails
                                                                                                        handleLoader={handleLoader}
                                                                                                        apiCall={apiCall}
                                                                                                        coreContentItem={coreContentItem}
                                                                                                        key={i}
                                                                                                        courseDetails={courseDetails}
                                                                                                        startUi={startUi}
                                                                                                        startUiUnenroll={startUiUnenroll}
                                                                                                        isVisibleBtn={true}
                                                                                                        prerequisiteCourse={prerequisiteCourse}
                                                                                                        crumbs={crumbs}
                                                                                                        name='vishal6666'
                                                                                                        showErrorScreen={showErrorScreen}
                                                                                                    />
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </Tab>
                                                                                }
                                                                            </Tabs>
                                                                        </div>
                                                                        :

                                                                        courseInstances.length ?
                                                                            <div>
                                                                                <p className="ms-2"><p className="enrollment-header mb-4"> Select any one instance to enroll</p></p>
                                                                                {
                                                                                    courseInstances?.map((coreContentItem, i) => {
                                                                                        return <MultiInstence
                                                                                            key={i}
                                                                                            coreContentItem={coreContentItem}
                                                                                            courseDetails={courseDetails}
                                                                                            startUi={startUi}
                                                                                            setList={setList}
                                                                                            setDisabaledEnrollBtn={setDisabaledEnrollBtn}
                                                                                            setEnrollmentInstanceCounts={setEnrollmenInstanceCounts}
                                                                                        />
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            : <></>

                                                        )
                                                    :
                                                    courseDetails?.attributes?.loType === "learningProgram" ?
                                                        courseInstances?.length == 1 ?
                                                            prerequisite_id ?
                                                                <PrerequisiteCourseDetails
                                                                    coreContentItem={prerequisiteCourse?.find(item => item.instanceId == prerequisite_id)}
                                                                    courseDetails={courseDetails}
                                                                    startUi={startUi}
                                                                    isVisibleBtn={false}
                                                                />
                                                                :
                                                                // TODO - For Single Instance Learning path to Show PrerequisiteCourse 
                                                                <>
                                                                    {
                                                                        prerequisiteCourse.length > 0 ?
                                                                            <div className="prerequisite" >
                                                                                <>
                                                                                    <h3 className="fw-bold mb-4">Prerequisite
                                                                                        {
                                                                                            <><span>{(mandatoryCountForPrerequisite === 0) && <span className="optional-btn">Optional</span>}</span>
                                                                                            </>
                                                                                        }
                                                                                        {(mandatoryCountForPrerequisite !== 0) &&
                                                                                            (<><span>{(countOfPrerequisiteLength === mandatoryCountForPrerequisite) ? <span className="section-btn">Required {mandatoryCountForPrerequisite} out of {countOfPrerequisiteLength}</span> : null}</span><span>{(countOfPrerequisiteLength !== mandatoryCountForPrerequisite) ? <span className="section-btn">Required {mandatoryCountForPrerequisite} out of {countOfPrerequisiteLength}</span> : null}</span></>)
                                                                                        }
                                                                                    </h3>
                                                                                </>
                                                                                {
                                                                                    prerequisiteCourse?.map((coreContentItem, i) => {
                                                                                        return <PrerequisiteCourse
                                                                                            key={i}
                                                                                            coreContentItem={coreContentItem}
                                                                                            courseDetails={courseDetails}
                                                                                            startUi={startUi}
                                                                                            mandatoryCountForPrerequisite={mandatoryCountForPrerequisite}
                                                                                            countOfPrerequisiteLength={countOfPrerequisiteLength}
                                                                                        />
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            : <></>
                                                                    }
                                                                    {/* ----------For LP section wise data  - all data for  single -------- */}
                                                                    {
                                                                        sectionsArr?.map((section) => {
                                                                            let isLocalizedMetadata = section?.localizedMetadata;
                                                                            let sectionName;
                                                                            if (isLocalizedMetadata) {
                                                                                sectionName = section?.localizedMetadata[0]?.name || "";
                                                                            }
                                                                            let isMandatory = section?.mandatory || false;
                                                                            let isMandatoryLOCount = section?.mandatoryLOCount || 0;
                                                                            let loIdsLength = section?.loIds?.length;
                                                                            let sectionId = section?.sectionId;
                                                                            let isCourseMandatory = isMandatoryLOCount === loIdsLength
                                                                            const sectionBorderStyle = { border: "2px solid #bdd4e7" };
                                                                            const sectionPaddingStyle = { padding: "15px" };
                                                                            const sectionMarginBottomStyle = { marginBottom: "15px" };
                                                                            return (
                                                                                <div
                                                                                    key={sectionId}
                                                                                    style={{
                                                                                        ...sectionBorderStyle,
                                                                                        ...sectionPaddingStyle,
                                                                                        ...sectionMarginBottomStyle
                                                                                    }}>
                                                                                    <h3 className={`fw-bold mb-4 ${!sectionName ? "no-margin" : ""}`}>{sectionName}

                                                                                        {isMandatory ? (
                                                                                            // isMandatoryLOCount !== loIdsLength ? (
                                                                                            <>
                                                                                                <span className="section-btn"> Required {isMandatoryLOCount} out of {loIdsLength}</span>
                                                                                            </>
                                                                                            // ) : null
                                                                                        ) : (
                                                                                            <span className="optional-btn">Optional</span>
                                                                                        )}</h3>


                                                                                    {
                                                                                        courseInstances &&
                                                                                        courseInstances[0]?.instance?.map((coreContentItem, index) => {
                                                                                            let isAvailableInSection = section?.loIds?.find(
                                                                                                (e) => e === coreContentItem?.id
                                                                                            );

                                                                                            let isPreviousInstanceCompleted =
                                                                                                index === 0 ||
                                                                                                courseInstances[0]?.instance[index - 1]?.progressPercent === 100 ||
                                                                                                courseInstances[0]?.instance[index - 1]?.hasPassed === true;
                                                                                            isPreviousInstanceCompleted = courseDetails.attributes.isSubLoOrderEnforced ? isPreviousInstanceCompleted : true;
                                                                                            if (isAvailableInSection === coreContentItem?.id) {
                                                                                                return (
                                                                                                    <MultiInstanceDetails
                                                                                                        handleLoader={handleLoader}
                                                                                                        apiCall={apiCall}
                                                                                                        key={index}
                                                                                                        coreContentItem={coreContentItem}
                                                                                                        courseDetails={courseDetails}
                                                                                                        startUi={startUi}
                                                                                                        startUiUnenroll={startUiUnenroll}
                                                                                                        prerequisiteCourse={prerequisiteCourse}
                                                                                                        crumbs={crumbs}
                                                                                                        isVisibleBtn={true}
                                                                                                        isPreviousInstanceCompleted={isPreviousInstanceCompleted}
                                                                                                        isCourseMandatory={isCourseMandatory}
                                                                                                        showErrorScreen={showErrorScreen}
                                                                                                    />
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        })}
                                                                                </div>
                                                                            );
                                                                        })}

                                                                </>
                                                            :
                                                            (
                                                                instance_id ?
                                                                    // TODO -For Multi Instance Learning path to Show PrerequisiteCourse
                                                                    (
                                                                        <>
                                                                            {
                                                                                // -----Prerequisite
                                                                                prerequisiteCourse.length > 0 ?
                                                                                    <div className="prerequisite">
                                                                                        <>
                                                                                            <h3 className="fw-bold mb-4">Prerequisite
                                                                                                {
                                                                                                    <><span>{(mandatoryCountForPrerequisite === 0) && <span className="optional-btn">Optional</span>}</span>
                                                                                                    </>
                                                                                                }
                                                                                                {(mandatoryCountForPrerequisite !== 0) &&
                                                                                                    (<><span>{(countOfPrerequisiteLength === mandatoryCountForPrerequisite) ? <span className="section-btn">Required {mandatoryCountForPrerequisite} out of {countOfPrerequisiteLength}</span> : null}</span><span>{(countOfPrerequisiteLength !== mandatoryCountForPrerequisite) ? <span className="section-btn">Required {mandatoryCountForPrerequisite} out of {countOfPrerequisiteLength}</span> : null}</span></>)
                                                                                                }
                                                                                            </h3>
                                                                                        </>
                                                                                        {
                                                                                            prerequisiteCourse?.map((coreContentItem, i) => {
                                                                                                return <PrerequisiteCourse
                                                                                                    key={i}
                                                                                                    coreContentItem={coreContentItem}
                                                                                                    courseDetails={courseDetails}
                                                                                                    startUi={startUi}
                                                                                                    mandatoryCountForPrerequisite={mandatoryCountForPrerequisite}
                                                                                                    countOfPrerequisiteLength={countOfPrerequisiteLength}
                                                                                                />
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    : <></>
                                                                            }
                                                                            {/* ----------For LP section wise data- for multi instance ------- */}
                                                                            {
                                                                                sectionsArr?.map((section) => {
                                                                                    let isLocalizedMetadata = section?.localizedMetadata;
                                                                                    let sectionName;
                                                                                    if (isLocalizedMetadata) {
                                                                                        sectionName = section?.localizedMetadata[0]?.name || "";
                                                                                    }
                                                                                    let isMandatory = section?.mandatory || false;
                                                                                    let isMandatoryLOCount = section?.mandatoryLOCount || 0;
                                                                                    // let sectionsArrLength = sectionsArr?.length;
                                                                                    let loIdsLength = section?.loIds?.length;
                                                                                    let sectionId = section?.sectionId;
                                                                                    let isCourseMandatory = isMandatoryLOCount === loIdsLength
                                                                                    const sectionBorderStyle = { border: "2px solid #bdd4e7" };
                                                                                    const sectionPaddingStyle = { padding: "15px" };
                                                                                    const sectionMarginBottomStyle = { marginBottom: "15px" };
                                                                                    return (
                                                                                        <div
                                                                                            key={sectionId}
                                                                                            style={{
                                                                                                ...sectionBorderStyle,
                                                                                                ...sectionPaddingStyle,
                                                                                                ...sectionMarginBottomStyle,
                                                                                            }}>
                                                                                            <h3 className={`fw-bold mb-4 ${!sectionName ? "no-margin" : ""}`}>{sectionName}

                                                                                                {isMandatory ? (
                                                                                                    // isMandatoryLOCount !== loIdsLength ? (
                                                                                                    <span className="section-btn">
                                                                                                        Required {isMandatoryLOCount} out of {loIdsLength}
                                                                                                    </span>
                                                                                                    // ) : null
                                                                                                ) : (
                                                                                                    <span className="optional-btn">Optional</span>
                                                                                                )}
                                                                                            </h3>
                                                                                            {courseInstances && courseInstances?.find((coreContentItem) => coreContentItem.instanceId === instance_id)?.instance?.map((coreContentItem, index) => {
                                                                                                let isAvailableInSection = section?.loIds?.find(
                                                                                                    (e) => e === coreContentItem?.id
                                                                                                );
                                                                                                let isPreviousInstanceCompleted = index === 0 || courseInstances[0]?.instance[index - 1]?.progressPercent === 100 ||
                                                                                                    courseInstances[0]?.instance[index - 1]?.hasPassed === true;
                                                                                                isPreviousInstanceCompleted = courseDetails.attributes.isSubLoOrderEnforced ? isPreviousInstanceCompleted : true;
                                                                                                if (isAvailableInSection === coreContentItem?.id) {
                                                                                                    return (
                                                                                                        <MultiInstanceDetails
                                                                                                            handleLoader={handleLoader}
                                                                                                            apiCall={apiCall}
                                                                                                            key={index}
                                                                                                            coreContentItem={coreContentItem}
                                                                                                            courseDetails={courseDetails}
                                                                                                            startUi={startUi}
                                                                                                            startUiUnenroll={startUiUnenroll}
                                                                                                            prerequisiteCourse={prerequisiteCourse}
                                                                                                            crumbs={crumbs}
                                                                                                            isVisibleBtn={true}
                                                                                                            isPreviousInstanceCompleted={isPreviousInstanceCompleted}
                                                                                                            isCourseMandatory={isCourseMandatory}
                                                                                                            showErrorScreen={showErrorScreen}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                                return null;
                                                                                            })}
                                                                                        </div>
                                                                                    );
                                                                                })}

                                                                        </>)
                                                                    :
                                                                    prerequisite_id ?
                                                                        <PrerequisiteCourseDetails
                                                                            coreContentItem={prerequisiteCourse?.find(item => item.instanceId == prerequisite_id)}
                                                                            courseDetails={courseDetails}
                                                                            startUi={startUi}
                                                                            isVisibleBtn={false}
                                                                        />
                                                                        :
                                                                        courseInstances.length > 0 ?
                                                                            <div>
                                                                                <p className="enrollment-header mb-4"> Select any one instance to enroll </p>
                                                                                {
                                                                                    courseInstances?.map((coreContentItem, i) => {
                                                                                        return <MultiInstence
                                                                                            key={i}
                                                                                            coreContentItem={coreContentItem}
                                                                                            courseDetails={courseDetails}
                                                                                            startUi={startUi}
                                                                                            setList={setList}
                                                                                            setDisabaledEnrollBtn={setDisabaledEnrollBtn}
                                                                                            setEnrollmentInstanceCounts={setEnrollmenInstanceCounts}
                                                                                        />
                                                                                    })
                                                                                }
                                                                            </div> : <></>
                                                            )
                                                        : courseDetails?.attributes?.loType === "certification" ?
                                                            courseInstances?.length == 1 ?
                                                                prerequisite_id ?
                                                                    <PrerequisiteCourseDetails
                                                                        coreContentItem={prerequisiteCourse?.find(item => item.instanceId == prerequisite_id)}
                                                                        courseDetails={courseDetails}
                                                                        startUi={startUi}
                                                                        isVisibleBtn={false}
                                                                    />
                                                                    :
                                                                    <div className="Certificate-block"><h3 className="mb-4">
                                                                        {courseInstances && (courseInstances[0].loResourceCompletionCount ? <span className="section-btn ms-0">Required {courseInstances[0].loResourceCompletionCount} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span> : null)}
                                                                        {courseInstances && (courseInstances[0].loResourceCompletionCount === undefined ? <span className="section-btn ms-0">Required {courseInstances[0].learningObjectInstanceModuleLength} out of {courseInstances[0]?.learningObjectInstanceModuleLength}</span> : null)}
                                                                    </h3>
                                                                        {
                                                                            courseInstances && courseInstances[0]?.instance?.map((coreContentItem, i) => {
                                                                                let isPreviousInstanceCompleted = i === 0 || courseInstances[0]?.instance[i - 1]?.progressPercent === 100 ||
                                                                                    courseInstances[0]?.instance[i - 1]?.hasPassed === true
                                                                                {/* learning path odered  */ }
                                                                                return <CertificateInstanceDetails
                                                                                    handleLoader={handleLoader}
                                                                                    apiCall={apiCall}
                                                                                    key={i}
                                                                                    courseIndex={i}
                                                                                    coreContentItem={coreContentItem}
                                                                                    courseDetails={courseDetails}
                                                                                    startUi={startUi}
                                                                                    startUiUnenroll={startUiUnenroll}
                                                                                    prerequisiteCourse={prerequisiteCourse}
                                                                                    crumbs={crumbs}
                                                                                    isVisibleBtn={true}
                                                                                    isPreviousInstanceCompleted={isPreviousInstanceCompleted}
                                                                                    showErrorScreen={showErrorScreen}
                                                                                />
                                                                            })
                                                                        }
                                                                    </div>
                                                                :
                                                                (
                                                                    instance_id ?
                                                                        courseInstances && courseInstances?.find((coreContentItem) => coreContentItem.instanceId === instance_id)?.instance?.map((coreContentItem, i) => {
                                                                            let isPreviousInstanceCompleted = i === 0 || courseInstances[0]?.instance[i - 1]?.progressPercent === 100 ||
                                                                                courseInstances[0]?.instance[i - 1]?.hasPassed === true
                                                                            return <CertificateInstanceDetails
                                                                                handleLoader={handleLoader}
                                                                                apiCall={apiCall}
                                                                                key={i}
                                                                                coreContentItem={coreContentItem}
                                                                                courseDetails={courseDetails}
                                                                                startUi={startUi}
                                                                                startUiUnenroll={startUiUnenroll}
                                                                                prerequisiteCourse={prerequisiteCourse}
                                                                                crumbs={crumbs}
                                                                                isVisibleBtn={true}
                                                                                isPreviousInstanceCompleted={isPreviousInstanceCompleted}
                                                                                showErrorScreen={showErrorScreen}
                                                                            />
                                                                        })
                                                                        :
                                                                        prerequisite_id ?
                                                                            <PrerequisiteCourseDetails
                                                                                coreContentItem={prerequisiteCourse?.find(item => item.instanceId == prerequisite_id)}
                                                                                courseDetails={courseDetails}
                                                                                startUi={startUi}
                                                                                isVisibleBtn={false}
                                                                            />
                                                                            :
                                                                            courseInstances.length > 0 ?
                                                                                <div>
                                                                                    <p className="enrollment-header fw-bold mb-4"> Select any one instance to enroll </p>
                                                                                    {
                                                                                        courseInstances?.map((coreContentItem, i) => {
                                                                                            return <MultiInstence
                                                                                                key={i}
                                                                                                coreContentItem={coreContentItem}
                                                                                                courseDetails={courseDetails}
                                                                                                startUi={startUi}
                                                                                                setList={setList}
                                                                                                setDisabaledEnrollBtn={setDisabaledEnrollBtn}
                                                                                                setEnrollmentInstanceCounts={setEnrollmenInstanceCounts}
                                                                                            />
                                                                                        })
                                                                                    }
                                                                                </div> : <></>
                                                                )

                                                            : <></>
                                            }
                                        </div>
                                    </Col>
                                    {isLPInstanceList &&
                                        <Col sm={12} md={5} lg={4} xl={4} xxl={3}>

                                            {
                                                errorMsg ? "" : <div>
                                                    {/* <span> {!(courseInstances?.length > 2) && (!instance_id) ? startUi(courseDetails?.id ?? null) : instance_id ? startUi(courseDetails?.id ?? null) : null}</span> */}
                                                    <span> {course_id || instance_id ? startUi(courseDetails?.id ?? null) : null}</span>
                                                    {/* <span>
                        { ( learningObject.length > 0 && courseInstances.length > 0) ? startUi(courseDetails?.id) : null  }
                      </span> */}
                                                </div>
                                            }
                                            {/* to unenroll course */}
                                            {
                                                errorMsg ? "" : <div>

                                                    <span> {course_id || instance_id ? startUiUnenroll() : null}</span>

                                                </div>
                                            }
                                            {!(learningObjectInstanceEnrollmentDetails) && (courseDetails?.relationships?.instances?.data?.length > 1) &&
                                                // <div className="roleSkill px-4 py-4 my-3">
                                                //   <div className="footSection d-flex align-items-center justify-content-start">
                                                //   </div>

                                                <button className="btn primary-btn mt-3 me-3 submitapproval enrollbtn"
                                                    onClick={() => { handleViewAllInstances() }}>View All Instances</button>

                                                // </div>
                                            }
                                            {
                                                learningObjectInstanceEnrollmentDetails && learningObjectInstanceEnrollmentDetails.attributes.state === "COMPLETED" && containsL1FeedbackInfo && (
                                                    <FeedbackModal
                                                        t={t}
                                                        fromNavigation={fromNavigation}
                                                        courseDetails={courseDetails}
                                                        learningObjectInstanceEnrollmentDetails={learningObjectInstanceEnrollmentDetails}
                                                    />
                                                )
                                            }
                                            {((learningObjectInstanceEnrollmentDetails?.attributes?.state === "ENROLLED" ||
                                                learningObjectInstanceEnrollmentDetails?.attributes?.state === "STARTED" ||
                                                learningObjectInstanceEnrollmentDetails?.attributes?.state === "COMPLETED"
                                            ) && (courseDetails?.attributes?.loType !== "jobAid" && courseDetails?.attributes?.loType !== "certification"))
                                                && (
                                                    <div className="rating-container p-4 mt-2">
                                                        <span className="rating-title mt-2">Rating</span>
                                                        <div className={(rate === undefined || rate === 0) ? "pt-2 pb-3 ratingImg" : "pt-2 pb-3 ratingImg submittedRatings"}>
                                                            <span>
                                                                <RatingFeedBack
                                                                    number={ratting || 0}
                                                                    handleRating={(rate === undefined || rate === 0) ? handleRating : handleRatingEmpty}
                                                                />
                                                            </span>
                                                        </div>
                                                        <button onClick={handleSubmitRating} className={(rate === undefined || rate === 0) ? "btn primary-btn my-2 submitapproval" : "btn primary-btn my-2 submitapproval d-none"} >Submit</button>
                                                        <ModalforSuccessAndFailure messageforPopup={messageforPopup} show={showModal} onClose={handleCloseModal} />
                                                    </div>
                                                )}

                                            {
                                                courseDetails?.attributes?.loType !== "certification" && courseCompleteDate !== "" && (<div className="roleSkill px-4 py-3 my-4">
                                                    <span className="roleSkillsTitle mb-3">Due Date</span>
                                                    <div>
                                                        {/* <p className="mt-2">Completion - {moment(courseCompleteDate?.attributes?.completionDeadline).format("MMM Do YYYY")}</p> */}

                                                        <p className="mt-2">Completion - {moment(courseCompleteDate).format("MMM Do YYYY")}</p>


                                                    </div>
                                                </div>)
                                            }
                                            {
                                                courseDetails?.attributes?.loType === "certification" && courseCompleteDate !== "" && (<div className="roleSkill px-4 py-4 my-4">
                                                    <span className="roleSkillsTitle ">Due Date</span>
                                                    <div className=" scroller my-4">
                                                        <p>Completion - {moment(courseCompleteDate).format("MMM Do YYYY")}</p>

                                                    </div>
                                                </div>)
                                            }

                                            {matchingSkills.length !== 0 &&
                                                <div className="roleSkill px-4 py-4 my-3">
                                                    <span className="roleSkillsTitle ">Role Based Skills</span>
                                                    <div className=" scroller my-4">
                                                        {matchingSkills?.sort((a, b) => a.level - b.level)?.map((e, i) => {
                                                            return <Form.Check key={i} checked={setCheckedData(e)} type="checkbox" onClick={(event) => handleSelectedSkils(event, e)} label={<span>{e.name} (Proficiency Level {e.level})</span>} />;
                                                        })}
                                                    </div>
                                                    <div className="footSection d-flex align-items-center justify-content-start">

                                                        <button className="btn primary-btn my-2 me-3 submitapproval" onClick={() => handleChange()}>Assign Skill(s) to Myself</button>
                                                        {
                                                            isManager && (
                                                                <button className="btn primary-btn my-2 me-3 submitapproval" onClick={() => {
                                                                    handleAssignSkillToLerner(selectedSkillIds)
                                                                }}>Assign Skill(s) to Learner</button>

                                                            )
                                                        }
                                                        <button className="btn primary-btn my-2 me-3 submitapproval" onClick={handleSubmit}>
                                                            Submit Skill(s) for Approval
                                                        </button>
                                                    </div>
                                                </div>}

                                            {/* TODO - view all instances - when we are not enrolled to any instance that time view all instances button will shown on Ui, after clicking on this you will navigate to listing page */}
                                            {/*TODO - showing Job Aid */}
                                            {
                                                (jobAids.length > 0 && courseDetails?.attributes?.loType !== "jobAid") &&
                                                <div className="rating-container p-4 mt-2">
                                                    <span className="rating-title mt-2">Job Aid</span>
                                                    <div className="pt-2 pb-3 ratingImg">
                                                        {
                                                            jobAids.map((item, i) => (<p key={i}><a style={{ textDecoration: "none" }} target="_blank" href={item?.location} rel="noreferrer">{item?.name}</a></p>)
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {/* TODO - showing resourceAids  */}

                                            {
                                                (resourceAids.length > 0 && courseDetails?.attributes?.loType !== "jobAid") &&
                                                <div className="rating-container p-4 mt-2">
                                                    <span className="rating-title mt-2">Resources</span>
                                                    <div className="pt-2 pb-3 ratingImg">
                                                        {
                                                            resourceAids.map((item, i) => (
                                                                <p key={i}>
                                                                    {
                                                                        /* Check if user is enrolled */
                                                                        learningObjectInstanceEnrollmentdata ? (
                                                                            <a
                                                                                style={{ textDecoration: "none" }}
                                                                                target="_blank"
                                                                                href={item?.location}
                                                                                rel="noreferrer"
                                                                            >
                                                                                {item?.name}
                                                                            </a>
                                                                        ) : (
                                                                            <span style={{ color: "gray", cursor: "not-allowed" }}>
                                                                                {item?.name}
                                                                            </span>
                                                                        )
                                                                    }
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            }


                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </div>
                    </Row>
                </Container>
            )}
            <AssignRoleToTeam
                handleShow={handleShow}
                showModal={showModal}
                assignRoleSkillGrpIds={handleAssignSkillToMySelf}
                selectedOptionForLerner={selectedOptionForLerner}
                name="Select Learner(s)"
                setCheckUserRes={setCheckUserRes}
                set_UserId={set_UserId}
                displayMessageBoxForConfirmation={displayMessageBoxForConfirmation}
                setDisplayMessageBoxForConfirmation={setDisplayMessageBoxForConfirmation}
                setDisplayMessageBoxForalreadyAssignRoleSkill={setDisplayMessageBoxForalreadyAssignRoleSkill}
                setDisplayMessageBoxForConfirmationCourse={setDisplayMessageBoxForConfirmationCourse}
                displayMessageBoxForalreadyAssignRoleSkill={displayMessageBoxForalreadyAssignRoleSkill}
                getPayloadForValidation={getPayloadForValidation}
                handleCloseDisplayMessage={handleCloseDisplayMessage}
                state={skillPayload}
            />
            <Scroller />
            <div className="modal-container-skills">
                <Modal show={displayMessageBox} centered>
                    <Modal.Body>
                        <div className="failure-modal-container-body">
                            <img className="failed-icon" src={failed_icon} alt="failed"></img>
                            <div className="failure-text-message success-text-message2">
                                One or more selected Skill(s) are not assigned to you. Do you still want to proceed?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="failure-modal-container-footer">
                            <button className="btn btn-link p-2" onClick={() => handleCloseDisplayMessage()}>
                                Cancel
                            </button>
                            <button className="btn primary-btn primary-blue m-2" onClick={() => handleOkDisplayMessage()}>
                                Ok
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={displayMessageBoxForConfirmation}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img className="failed-icon mx-auto" src={Failure_Icon} alt="failed"></img>
                            {
                                assignToLearner ?
                                    <div className="success-text-message2 p-0 pt-3">
                                        Are you sure you want to assign selected Skill(s) to the selected Learner(s) ?
                                    </div> :
                                    <div className="success-text-message2 p-0 pt-3">
                                        Are you sure you want to assign selected Skill(s) to you?
                                    </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-link m-2" onClick={() => handleCloseConfirmForAssign()}>
                            Cancel
                        </button>
                        <button className="btn primary-btn primary-blue m-2" onClick={() => handleConfirmForAssign()}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={displayMessageBoxForConfirmationCourse}>
                    <Modal.Body>
                        <div className="success-modal-container my-5 text-center">
                            <img className="failed-icon mx-auto" src={Failure_Icon} alt="failed"></img>
                            <div className="success-text-message2 p-0 pt-3">
                                Are you sure you want to enroll the selected Learner(s)?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-link m-2" onClick={() => handleCloseConfirmForAssignCourse()}>
                            Cancel
                        </button>
                        <button className="btn primary-btn primary-blue m-2" onClick={() => handleConfirmForAssignCourse()}>
                            Ok
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            {/* this is modal for unenrollment */}
            <div>
                <Modal show={showModall} centered>
                    <Modal.Header>
                        <Modal.Title>Confirmation Required</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="failure-modal-container-body">
                            <img className="failed-icon" src={failed_icon} alt="failed"></img>
                            <div className="failure-text-message success-text-message2">
                                <p>
                                    Unenrolling will delete all your progress data and personal information like Notes and Quiz Score (if any).
                                </p>
                                <p>
                                    Are you sure you want to continue?
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="failure-modal-container-footer">
                            <button className="btn btn-link p-2" onClick={() => handleCloseDisplayMessageOne()}>
                                Cancel
                            </button>
                            <button className="btn primary-btn primary-blue m-2" onClick={() => handleOkDisplayMessageOne()}>
                                Ok
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>



            {/*  */}
            <Modal centered show={displayMessageBoxForalreadyAssignRoleSkill}>
                <Modal.Body>
                    <div className="success-modal-container my-5 text-center">
                        <img className="failed-icon mx-auto" src={Failure_Icon} alt="failed"></img>
                        <div className="success-text-message2 p-0 pt-3">
                            {assignToLearner ? (
                                checkUserRes?.assignUserSkills?.msg === "all_assigned" ? (
                                    <>Selected Skill(s) are already assigned to selected Learner(s).</>
                                ) : (
                                    <>
                                        One or more Skill(s) are already assigned to some of the selected Learner(s). Do you want to proceed with assigning selected Skill(s) to rest of the Learner(s)?
                                    </>
                                )
                            ) : checkUserRes?.assignUserSkills?.msg === "all_assigned" ? (
                                <>Selected Skill(s) are already assigned to you.</>
                            ) : (
                                <>
                                    One or more Skill(s) are already assigned to you.
                                    Do you want to proceed with assigning rest of the Skill(s)?
                                </>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!(checkUserRes?.assignUserSkills.response) && (checkUserRes?.assignUserSkills?.msg === "all_assigned") ? (
                        <>
                            <button
                                className="btn primary-btn primary-blue m-2"
                                onClick={() => handleCloseDisplayMessage()}>
                                Ok
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="btn btn-link m-2" onClick={() => handleCloseDisplayMessage()}>
                                Cancel
                            </button>
                            <button className="btn primary-btn primary-blue m-2" onClick={() => handleConfirmForAssign()}>
                                Yes
                            </button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            <ModalforSuccessAndFailure messageforPopup={messageforPopup} show={showModalforUrl} warningPopup={warningPopup} onClose={handleCloseModal} messageForFailure={messageForFailure} />
            {errorScreen && <ErrorScreen/>}
        </>
    );
};

export default AllCourses;
